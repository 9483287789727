import TextInput from '../inputs/textInput'
import * as yup from 'yup'
import ProjectType, { ProjectTypeCustomErrorFunction, ProjectTypeValidationFunction } from '../inputs/projectType'
import Radio from '../inputs/radio'
import { getQuestionById, radioDefaultValidation } from '../briefHelpers'

const data = {
  id: 'PROJECT',
  active: true,
  title: 'Pomôžte nám pochopiť Váš projekt',
  indicatorTitle: 'Projekt',
  questions: [
    {
      id: 'PROJECT_NAME',
      component: TextInput,
      data: {
        title: 'Názov projektu',
        subtitle: 'Napríklad portál Letenky za babku, eshop Wineplanet a pod.',
        required: true,
        type: 'text',
        validationSchema: yup.string().required(),
        customErrorMessage: 'Pre pokračovanie v dotazníku napíšte názov projektu'
      },
    },
    {
      id: 'PROJECT_TYPE',
      component: ProjectType,
      data: {
        customErrorMessage: ProjectTypeCustomErrorFunction,
        title: 'Čo všetko potrebujete?',
        required: true,
        validationFunction: ProjectTypeValidationFunction,
      },
      value: {
        website: false,
        app: false,
        design: false,
        websiteType: {
          selected: {
            id: null,
            data: {},
          },
        },
        appWebsite: {
          selected: {
            id: null,
          },
        },
      },
    },
    {
      id: 'PROJECT_DOCUMENTATION',
      component: Radio,
      active: (formValue) => {
        const question = getQuestionById(formValue, 'PROJECT_TYPE')
        const value = question.value
        return value.website || value.app
      },
      data: {
        title: 'Pustíme sa spolu najskôr do dokumentácie?',
        subtitle: 'Väčšie projekty si vyžadujú profesionálne spracované zadanie.',
        required: true,
        options: [
          {
            id: 'YES',
            label: 'Budeme chcieť s vami vypracovať profesionálnu dokumentáciu (väčšie projekty)',
            type: 'default',
          },
          {
            id: 'NO',
            label: 'Máme pripravené vlastné zadanie',
            type: 'file',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {
          id: null,
          data: {},
        },
      },
    },
  ],
}

export default data