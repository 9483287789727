import React, { Component } from 'react'
import Switch from './switch'
import { childValue } from '../briefHelpers'
import File from './fileUploader'
import List from './list'

export const LogoDescriptionValidation = (value, data) => {
  if (value == null) {
    return true
  }
  
  const { toggle, text, file } = value
  if (toggle) {
    data.required = true
    return (text && text.newInput ) || (file != null && file.files.length)
    
  } else {
    data.required = false
    return true
  }
}

class LogoDescription extends Component {
  render() {
    const { value, setValue, options } = this.props

    const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue)
    const [textValue, textSetValue] = childValue('text', value, setValue)
    const [fileValue, fileSetValue] = childValue('file', value, setValue)


    return (
      <div>
        <Switch
          value={toggleValue}
          setValue={toggleSetValue}
          options={{
            labelLeft: 'Necháme na Vás',
            labelRight: 'Áno',
          }}
        />
        {toggleValue &&
          <div className={`mt-4 fade ${toggleValue ? 'visible' : 'hidden'}`}>
            <List setValue={textSetValue} value={textValue} autofocus={true} options={{
              ...options,
              placeholder: 'Opíšte prípadne použite odkazy',
            }} />
            <File setValue={fileSetValue} value={fileValue} options={options} />
          </div>
        }
      </div>
    )
  }
}

export default LogoDescription