import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RadioOption extends Component {
  render() {
    const { label, onChange, selected, children } = this.props
    
    return (
      <div
        className={`radio-option avoid ${selected ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          onChange()
        }}>
        <div className={'d-flex align-items-center'}>
          <div className={`box`}>
            <i className="la la-check"/>
          </div>
          <div className={'label'}>
            {label}
          </div>
        </div>
        {children &&
          { children }
        }
      </div>
    )
  }
}

RadioOption.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  children: PropTypes.element,
}


export default RadioOption