import React, { Component } from 'react'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import briefApi from '../briefApi'
import * as PropTypes from 'prop-types'
import { getWindowWidth } from '../../../utilities'

const toFile = (url, name) => {
  return new Promise(resolve => {
    fetch(url).then(res => {
      res.arrayBuffer().then(buf => {
        const file = new File([buf], name, { type: 'application/x-binary' })
        resolve(file)
      })
    })
  })
}

class FileUpload extends Component {
  constructor(props) {
    super(props)
    const { value } = props

    this.state = {
      initFiles: null,
    }

    const files = (value && value.files) ? value.files : []
    
    const results = files.map(async (file) => await toFile(file.fileUrl, file.name))
    Promise.all(results).then((files) => {
      this.setState({
        initFiles: files == null ? [] : files,
      })
    })

    this.getUploadParams = this.getUploadParams.bind(this)
    this.handleChangeStatus = this.handleChangeStatus.bind(this)
  }

   getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
  }

  async getUploadParams() {
    return { url: await briefApi.getFileUploadUrl() }
  }

  handleChangeStatus(data, status) {
    const { setValue, value } = this.props

    if (status === 'done') {
      const response = JSON.parse(data.xhr.response)
      const fileUrl = response.fileUrl

      const newFile = {
        fileUrl: fileUrl,
        name: data.meta.name,
        id: data.meta.id,
      }

      setValue({
        files: (value && value.files) ? [...value.files, newFile] : [newFile],
      })
    }

    if (status === 'removed' && value) {
      setValue({
        files: value.files.filter((file) => {
          return data.meta.id !== file.id
        }),
      })
    }
  }

  handleSubmit(files, allFiles) {
    allFiles.forEach(f => f.remove())
  }

  render() {
    const { value, subtitle, subtitleMobile } = this.props

    const files = (value && value.files) ? value.files : []

    return (
      <div className={'file-upload avoid'} onClick={(e) => {
        e.stopPropagation()
      }}>
        <div className="file-list print-display">
          {files.map((file, i) => (
            <a key={i} href={file.fileUrl} target={'_blank'} rel="noreferrer" >{file.name}</a>
          ))}
        </div>
        {
          this.state.initFiles != null && <div className={'print-hide'}>
            <Dropzone
              getUploadParams={this.getUploadParams}
              onChangeStatus={this.handleChangeStatus}
              onSubmit={this.handleSubmit}
              inputContent={ getWindowWidth() > 1200 ? '(Voliteľné) Presuňte sem súbor alebo kliknite pre výber súboru' : '(Voliteľné) Presuňte sem súbor'}
              submitButtonContent={null}
              inputWithFilesContent={'Vybrať súbory'}
              initialFiles={this.state.initFiles}
            />
            <h4 className='text-center text-lg-left'>{
              getWindowWidth() > 1200 ? subtitle : subtitleMobile
            }</h4>
          </div>
        }
      </div>
    )
  }
}

FileUpload.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any,
  subtitle: PropTypes.string,
}

FileUpload.defaultProps = {
  subtitle: '(Môžete postupne nahrať aj viac súborov + skúste využiť aj funkciu drag & drop)',
  subtitleMobile: '(Môžete postupne nahrať aj viac súborov)',
}


export default FileUpload