import Radio from '../inputs/radio'
import { checkboxDefaultValidation, childValue, getQuestionById, radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import Switch from '../inputs/switch'
import React from 'react'
import SwitchConditionalRender from '../inputs/switchConditionalRender'
import ListTwoColumns from '../inputs/listTwoColumns'
import Checkbox from '../inputs/checkbox'
import Color, { ColorValidation } from '../inputs/color'
import FileUpload from '../inputs/fileUploader'
import BriefTooltip from '../BriefTooltip'
import * as yup from 'yup'

const WebImpressionValidation = (value, data) => {
  if (!value) return false

  const { radio } = value
  if (data.isRequiredDefValue !== 'undefiened') {
    data.required = data.isRequiredDefValue    
  }
  
  if (!radio) return true
  if (radio.selected && radio.selected.id === "OWN") {
    data.required = true
    if (!radio.selected.data || radio.selected.data.replace(/\s/g, "").length === 0) {
      return false
    }
  }
  return true
}


const wireFramesCount = ({ value, setValue }) => (
  
  <div className={'d-flex mb-3 mt-3 align-items-center'}>
    <span className={'mr-2'}>počet breakpointov:</span>
    <Switch
      setValue={setValue}
      value={value}
      options={{
        labelLeft: '3',
        labelRight: '5',
      }} />
    <div className='d-flex align-items-end ml-2'>
      <BriefTooltip content={'Počet individuálne navrhnutých rozlíšení'}></BriefTooltip>
    </div>
  </div>)

const WebImpression = (data) => {
  const { value, setValue } = data
  const [radioValue, radioSetValue] = childValue('radio', value, setValue)
  const [checkboxValue, checkboxSetValue] = childValue('checkbox', value, setValue)

  return (<>
    <Radio value={radioValue} setValue={radioSetValue} options={{
      options: [
        {
          id: 'UNKNOWN',
          label: 'Nechávame to úplne na Vás.',
          type: 'default',
        },
        {
          id: 'OWN',
          label: 'Napíšte vlastný:',
          placeholder: 'Vpíšte sem text',
          type: 'input',
        },
      ],
    }
    } />
    <Checkbox setValue={checkboxSetValue} value={checkboxValue} options={{
      options: [
        {
          id: 'YES',
          label: <>Budeme mať záujem o tvorbu <BriefTooltip content={'koláž farieb, obrázkov a prvkov určených pre design'}>moodboardu</BriefTooltip></>,
          type: 'default',
        },
      ],
    }} />
  </>
  )

}
const data = {
  id: 'DESIGN',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.website,
  title: 'UX/UI design',
  indicatorTitle: 'Design',
  questions: [
    {
      id: 'DESIGN_CONSULTATION',
      component: Radio,
      data: {
        title: 'Chcete s nami konzultovať jednotlivé rozloženia stránok?',
        subtitle: 'Podobne ako architekt. návrh budovy pred výstavbou. Logicky, prehľadne a intuitívne navrhnuté stránky pre návštevníkov zvyšujú váš profit.',
        required: true,
        options: [
          {
            id: 'WIRE_FRAMES',
            label: <div>Áno, navrhnite nám aj prototypy rozloženia <BriefTooltip content={'Schématické návrhy rozloženia každej podstránky'}>(wireframy)</BriefTooltip></div>,
            type: 'default',
          },
          {
            id: 'NO',
            label: 'Nevidíme potrebu konzultovať rozloženie',
            type: 'default',
          },
          {
            id: 'OWN_WIRE_FRAMES',
            label: 'Dodáme vlastné wireframy',
            type: 'file',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      /*  value: {
         selected: {
           id: 'NO',
           data: {},
         },
       }, */
    },
    {
      id: 'UX_TESTING',
      component: Switch,
      data: {
        title: <>Budete mať záujem o pokročilé používateľské testovanie <BriefTooltip content={'user experience - Užívateľská skúsenosť'}>UX</BriefTooltip>?</>,
        subtitle: 'Táto analýza nám pomôže v neskorších fázach vyťažiť z Vášho webu maximum výsledkov.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'RESPONSIVE_DESIGN',
      component: Radio,
      data: {
        title: 'Má byť webdesign prispôsobený na mobilné zariadenia / responzívny?',
        subtitle: 'Váš web sa tak bude pekne zobrazovať aj na mobiloch a tabletoch.',
        required: true,
        options: [
          {
            id: 'YES',
            label: 'Áno, štandardná responzivita',
            type: 'default',
          },
          {
            id: 'BREAK_POINTS_COUNT',
            label: 'Navrhnite nám všetky verzie namieru',
            type: 'custom',
            component: wireFramesCount,
          },
          {
            id: 'NO',
            label: 'Nepotrebujeme responzivitu (neodporúča sa)',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      /* value: {
        selected: {
          id: 'YES',
          data: {},
        },
      }, */
    },
    {
      id: 'WEBSITE_INSPIRATION',
      component: SwitchConditionalRender,
      data: {
        title: 'Viete uviesť webstránky, ktoré sa Vám páčia?',
        required: false,
        useEnterKey: true,
        validationFunction: (value, data) => {
          if (value == null) return false
          data.required = true
          const { conditionalRender, switchValue } = value
          if (switchValue === false) {
            data.required = false
            return true
          } else if (conditionalRender && conditionalRender.newInputCol1) {
       
            return yup.string().matches(
              /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-za-z0-9_-]+(\.[a-za-z]+)+(\/)?.([\w\?[a-za-z-_%\/@?]+)*([^\/\w\?[a-za-z0-9_-]+=\w+(&[a-za-z0-9_]+=\w+)*)?$/,
              'enter correct url!'
            ).required('please enter website').isValidSync(conditionalRender.newInputCol1)
          } else if (conditionalRender && conditionalRender.items && conditionalRender.items.length > 0) {
            return true
          }
        },
        labelRight: 'Napíšeme',
        labelLeft: 'Nepotrebujeme uviesť',
        conditionalRenderTrue: {
          component: ListTwoColumns,
          data: {
            placeholderCol1: 'Napr. www.narative.sk',
            placeholderCol2: 'Čo sa vám na stránke páči...',
          },
        },
      },
      value: {
        switchValue: false
      },
    },
    {
      id: 'WEB_IMPRESSION',
      component: WebImpression,
      data: {
        title: 'Aký dojem by mal vyvolávat Váš nový web?',
        subtitle: 'Napr. profesionálny a dôveryhodný dojem alebo moderný svieži pre mladých, pre ženy, atď.',
        required: false,
        showStar: false,
        isRequiredDefValue: false,
        validationFunction: WebImpressionValidation,
      },
  /*     value: {
        radio: {
          selected: {
            id: 'UNKNOWN',
            type: 'default'
          },
        }
      }, */
    },
    {
      id: 'DESIGN_COLOR',
      component: Color,
      data: {
        title: 'Je nejaká farebnosť, ktorú na webe preferujete?',
        subtitle: 'Môžete navrhnúť a prejdeme si to spoločne, alebo za Vás rozhodneme my.',
        required: true,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vybrať farby kliknutím na štvorčeky',
        validationFunction: ColorValidation,
      },
      /* value: {
        toggle: {
          selected: {
            id: 'NO',
          },
        },
      }, */
    },
    {
      id: 'CURRENT_DESIGN_MANUAL',
      component: SwitchConditionalRender,
      data: {
        title: 'Máte existujúci design manuál, ktorý nám viete ukázať?',
        subtitle: 'Príručka k vizuálnej identite, vytvorená predošlým grafikom loga, webu a pod.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
        conditionalRenderTrue: {
          component: FileUpload,
          data: {},
        },
      },
      value: null,
    },
    {
      id: 'ADDITIONAL_WEB_DESIGN_SERVICES',
      component: Checkbox,
      data: {
        title: 'Doplnkové webdesign služby',
        subtitle: 'Lebo dokonalosť tkvie v detailoch.',
        required: false,
        options: [
          {
            id: 'EFFECT_KIT',
            label: <BriefTooltip content={'Balík efektov a animácií pre Váš web'}>Effect-kit</BriefTooltip>,
            type: 'default',
          },
          {
            id: 'UI_KIT',
            label: <BriefTooltip content={'Sada grafických prvkov určených na skladanie Vášho designu'}>UI-kit</BriefTooltip>,
            type: 'default',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
    },
  ],
}

export default data