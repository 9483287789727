import React, { Component } from 'react'
import Switch from './switch'
import { childValue } from '../briefHelpers'
import Checkbox from './checkbox'

export const BrandingValidation = (value, data) => {
  const { checkboxes } = value
  data.required = false
/*   if (!toggle) return true */
  if (!checkboxes.selected) return false
  const selected = checkboxes.selected

  const isSelected = Object.values(selected).find(item => item !== null)
  if (!isSelected) return false

  if (selected['OTHER'] && !selected['OTHER'].data) {
    data.required = true
    return false
  }
  return true
}

class Branding extends Component {
  render() {
    const { value, setValue } = this.props

 /*    const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue) */
    const [checkboxesValue, checkboxesSetValue] = childValue('checkboxes', value, setValue)


    return (
      <div>
       {/*  <Switch
          formValue={formValue}
          value={toggleValue}
          setValue={toggleSetValue}
          options={{
            labelRight: 'Áno',
            labelLeft: 'Nie',
          }}
        /> */}
        <div className={`mt-4 avoid`}>
          <Checkbox setValue={checkboxesSetValue} value={checkboxesValue} options={{
            options: [
              {
                id: 'BUSINESS_CARD',
                label: 'Vizitka',
                type: 'default',
              },
              {
                id: 'BANNER',
                label: 'Banner na web / Facebook a pod.',
                type: 'default',
              },
              {
                id: 'EMAIL_SIGNATURE',
                label: 'E-mailový podpis',
                type: 'default',
              },
              {
                id: 'HEADER',
                label: 'Hlavička / hlavičkový papier / obálka',
                type: 'default',
              },
              {
                id: 'FOLDER',
                label: 'Folder / zakladač',
                type: 'default',
              },
              {
                id: 'EMAIL',
                label: 'E-mailová šablóna / newsletter',
                type: 'default',
              },
              {
                id: 'BILLBOARD',
                label: 'Leták / billboard / brožúra / katalóg a pod.',
                type: 'default',
              },
              {
                id: 'PROMOTIONAL_ITEMS',
                label: 'Reklamné predmety, pero, polep auta, atď.',
                type: 'default',
              },
              {
                id: 'OTHER',
                label: 'Iné:',
                type: 'input',
                placeholder: 'Vpíšte sem text',
              },
            ],
          }}/>
        </div>
      </div>
    )
  }
}

export default Branding