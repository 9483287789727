import React, { Component } from 'react'
import BackButton from '../backButton/backButton'
import Layout from '../layout'
import BriefSidebarIndicator from './briefSidebarIndicator'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import imgBw from '../../images/nara_logo_720_r.webp'

class BriefLayout extends Component {
  componentDidMount() {
    document.documentElement.style.scrollBehavior = 'auto'
  }

  componentWillUnmount() {
    document.documentElement.style.scrollBehavior = 'smooth'
  }

  render() {
    const { children, hideIndicator, steps, currentStep, sections, allQuestionsCount, focusedQuestion } = this.props
    return (
      <div className={'brief-layout'} style={{ opacity: 0 }}>
        <header className={'brief'}>
          <div>
            <BackButton pathname={globalHistory.location.pathname}/>
            <Link to={'/'}>
              <img width={'151px'} height={'46px'} src={imgBw} alt="NaraDesign" className={'logo'}/>
            </Link>
          </div>
          <div>
            <span>V prípade otázok nám zavolajte na <a href={'tel:+421918040888'}>0918 040 888</a> alebo napíšte na <a href={'mailto://info@narative.sk'}> info@narative.sk</a></span>
          </div>
        </header>
        <BriefSidebarIndicator steps={steps} currentStep={currentStep} hide={hideIndicator} sections={sections} allQuestionsCount={allQuestionsCount} focusedQuestion={focusedQuestion} />
        <img src="/images/narative_logo_cropped.svg" width={'151px'} height={'46px'}  alt="NaraDesign" className={'watermark print-display'}/>
        <Layout showBackButton={true} className={'brief-wrapper'}>
          {children}
        </Layout>
      </div>
    )
  }
}

BriefLayout.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  hideIndicator: PropTypes.bool.isRequired,
}


export default BriefLayout
