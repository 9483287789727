import React, { Component } from 'react'
import Switch from './switch'
import { childValue } from '../briefHelpers'
import Checkbox from './checkbox'
import BriefTooltip from '../BriefTooltip'

export const WebsiteContentValidation = (value) => {
  return true
}

class WebsiteContent extends Component {
  render() {
    const { value, setValue, formValue } = this.props

    const [textToggleValue, textToggleSetValue] = childValue('textToggle', value, setValue)
    const [imagesToggleValue, imagesToggleSetValue] = childValue('imagesToggle', value, setValue)
    const [copyWritingValue, copyWritingSetValue] = childValue('copyWriting', value, setValue)
    const [contentMigrationValue, contentMigrationSetValue] = childValue('contentMigration', value, setValue)

    return (
      <div className='avoid'>
        <Switch
          formValue={formValue}
          value={textToggleValue}
          setValue={textToggleSetValue}
          options={{
            labelLeft: 'Nie, dodáme neskôr',
            labelRight: 'Áno',
          }}
        />
        <div className={`mt-4 fade ${!textToggleValue ? 'show' : 'hide'}`}>
          <Checkbox setValue={copyWritingSetValue} value={copyWritingValue} options={{
            options: [
              {
                id: 'COPYWRITING',
                label: <><BriefTooltip content={'Tvorba textov a obsahu'}>Prajeme si využiť Vaše služby copywritingu</BriefTooltip></>,
                type: 'default',
              },
            ],
          }}/>
        </div>

        <h3 className={'mt-3'}>Máte pripravené obrázky na Váš web?</h3>
        <h4>Obrázky, fotky a ich popis.</h4>

        <Switch
          formValue={formValue}
          value={imagesToggleValue}
          setValue={imagesToggleSetValue}
          options={{
            labelLeft: 'Nie, dodáme neskôr',
            labelRight: 'Áno',
          }}
        />
        <div className={`mt-4`}>
          <Checkbox setValue={contentMigrationSetValue} value={contentMigrationValue} options={{
            options: [
              {
                id: 'HELP_TEXT',
                label: 'Potrebujeme pomôcť s tvorbou textov, obsahu a pod.',
                type: 'default',
              },
              {
                id: 'CONTENT_MIGRATION',
                label: 'Budeme chcieť, aby ste nám skopírovali obsah z pôvodného webu',
                type: 'default',
              },
            ],
          }}/>
        </div>
      </div>
    )
  }
}

export default WebsiteContent