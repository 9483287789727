import React, { Component } from 'react'
import Switch from './switch'
import * as yup from 'yup'
import { childValue } from '../briefHelpers'
import TextInput from './textInput'

export const CurrentWebsiteValidation = (value, data) => {
  if (!value) return true
  const { toggle, address } = value
  if (!toggle) return true
  const isValied = yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-za-z0-9_-]+(\.[a-za-z]+)+(\/)?.([\w\?[a-za-z-_%\/@?]+)*([^\/\w\?[a-za-z0-9_-]+=\w+(&[a-za-z0-9_]+=\w+)*)?$/,
      'enter correct url!'
    )
    .required('please enter website').isValidSync(address?.toLowerCase())


  return isValied
}

class CurrentWebsite extends Component {
  constructor(props) {
    super(props)
    this.addressInputRef = React.createRef()
    this.prosInputRef = React.createRef()
    this.consInputRef = React.createRef()
    this.checkIsValid = this.checkIsValid.bind(this)


    this.state = {
      isValidAddress: false
    }

  }

  isRequiredIfIsOn(value) {
    this.props.options.required = value
  }


  checkIsValid(value) {
    this.setState({
      isValidAddress: yup.string().email().required().isValidSync(value)
    })
  }

  onSetToggle(val) {
    this.onFocusInput(!val)
  }

  onFocusInput(value) {
    this.props.options.useEnterKey = value
    this.props.setValue({
      refresh: true
    })
  }

  render() {
    const { value, setValue, formValue } = this.props
    const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue)
    const [addressValue, addressSetValue] = childValue('address', value, setValue)
    const [prosValue, prosSetValue] = childValue('pros', value, setValue)
    const [consValue, consSetValue] = childValue('cons', value, setValue)

    const inputOptions = {
      type: 'text',
    }

    return (
      <div className={'current-website avoid'}>
        <Switch
          formValue={formValue}
          value={toggleValue}
          setValue={(val) => {
            this.onSetToggle(val)
            if (val) {
              setTimeout(() => {
                if (this.addressInputRef && this.addressInputRef.current != null) {
                  this.addressInputRef.current.focus()
                }
              }, 1)
             
            }
            toggleSetValue(val)
            this.isRequiredIfIsOn(val)
            
          }}
          options={{
            labelLeft: 'Nie',
            labelRight: 'Áno',
          }}
        />
        {value != null && value.toggle && 
          <div className={`mt-4 ${value != null && value.toggle ? 'visible' : 'hidden'}`}>
            <h3>Napíšte jej adresu <span className='required'>*</span></h3>
            <TextInput
              onFocus={() => this.onFocusInput(false)}
              setValue={(val) => {
                addressSetValue(val)
                this.checkIsValid(val)
              }}
              value={addressValue}
              formValue={formValue}
              options={{
                ...inputOptions,
                ref: this.addressInputRef,
                onKeyPress: (e) => {
                  if (e) {
                    if (e.key === 'Enter') {
                      if (value == null || !value.toggle) return
                      e.preventDefault()
                      e.stopPropagation()
                      if (this.prosInputRef && this.prosInputRef.current) {
                        this.prosInputRef.current.focus()
                      }
                    }
                  }

                },

              }}
            />

            <h3>Čo na nej hodnotíte ako pozitívne?</h3>
            <h4>Napr. množstvo obsahu, farebnosť</h4>
            <TextInput
              setValue={prosSetValue}
              onFocus={() => this.onFocusInput(false)}
              value={prosValue}
              formValue={formValue}
              options={{
                ...inputOptions,
                ref: this.prosInputRef,
                onKeyPress: (e) => {
                  if (e) {
                    if (e.key === 'Enter') {
                      if (value == null || !value.toggle) return
                      e.preventDefault()
                      e.stopPropagation()
                      if (this.consInputRef && this.consInputRef.current) {
                        this.consInputRef.current.focus()
                      }
                    }
                  }
                },
              }}
            />

            <h3>Aké sú jej nedostatky? Čo jej chýba?</h3>
            <h4>Napr. neprehľadnosť, zastaraný design, nízke tržby, nie je od Narative...</h4>
            <TextInput
              onFocus={() => this.onFocusInput(true)}
              setValue={consSetValue}
              value={consValue}
              formValue={formValue}
              options={{ ...inputOptions, ref: this.consInputRef }}
            />

          </div>
        }

      </div>
    )
  }
}

export default CurrentWebsite