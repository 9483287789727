/* eslint-disable no-mixed-operators */
import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ChromePicker, SketchPicker } from 'react-color'
import { childValue } from '../briefHelpers'
import Radio from './radio'

export const ColorValidation = (value) => {
  if (!value) return false
  const { firstColor, secondColor, toggle } = value
  const noSelected = toggle && toggle.selected && toggle.selected.id === 'NO'

  return noSelected || (firstColor !== 'transparent' && secondColor !== 'transparent' && firstColor !== undefined && secondColor !== undefined)
}

class Color extends Component {


  render() {
    const { setValue, value, options } = this.props
    const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue)
    const firstColor = value && value.firstColor != null ? value.firstColor : '#0000'
    const secondColor = value && value.secondColor != null ? value.secondColor : '#0000'

    const firstColorBg = value && value.firstColor != null && value.firstColor !== 'transparent' ? firstColor : 'repeating-linear-gradient(45deg, #ebe9e9, #ebe9e9 10px, #d4d1d1 10px, #d4d1d1 20px)'
    const secondColorBg = value && value.secondColor != null && value.secondColor !== 'transparent' ? secondColor : 'repeating-linear-gradient(45deg, #ebe9e9, #ebe9e9 10px, #d4d1d1 10px, #d4d1d1 20px)'

    const active = (value && value.activeColor) ? value.activeColor : null


    const choseColor = toggleValue && toggleValue.selected && toggleValue.selected.id === 'YES'
    return (
      <div className={'color-input avoid'}>
        <Radio setValue={toggleSetValue} value={toggleValue} options={{
          ...options,
          options: [
            {
              id: 'NO',
              label: 'Necháme to úplne na Vás',
              type: 'default',
            },
            {
              id: 'YES',
              label: 'Vyberieme si dve farby (stačí približne)',
              type: 'default',
            },

          ],
        }} />
        <div className={`colors ${choseColor ? 'visible' : 'hidden'}`}>
          <strong>Farba 1:</strong>
          <div
            className={'color'}
            onClick={() => setValue({ activeColor: 'firstColor' })}
            style={{ background: firstColorBg }}
          >
            {active === 'firstColor' && <>
              <div
                className={'cover'}
                onClick={(e) => {
               
                  e.preventDefault()
                  e.stopPropagation()
                  setValue({ activeColor: null })
                }}
              >
              </div>
              <ChromePicker
                disableAlpha={true}
                onChange={color => {
                     console.log('e')
                  setValue({ [active]: color.hex })
                }}
                color={value && value[active] ? value[active] : firstColor} />

            </>}
          </div>
          <strong>Farba 2:</strong>
          <div
            className={'color'}
            onClick={() => setValue({ activeColor: 'secondColor' })}
            style={{ background: secondColorBg }}
          >
            {active === 'secondColor' && <>
              <div
                className={'cover'}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setValue({ activeColor: null })
                }}
              >
              </div>
              <ChromePicker
                disableAlpha={true}
                onChange={color => {
                  setValue({ [active]: color.hex })
                }}
                color={value && value[active] ? value[active] : firstColor} />

            </>}
          </div>
        </div>
      </div>
    )
  }
}

Color.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any.isRequired,
}


export default Color