import { checkboxDefaultValidation, getQuestionById } from '../briefHelpers'
import Checkbox from '../inputs/checkbox'
import * as yup from 'yup'
import TextInput from '../inputs/textInput'

const data = {
  id: 'OBJECTIVES',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.website,
  title: 'Ciele',
  indicatorTitle: 'Ciele',
  questions: [
    {
      id: 'WEBSITE_OBJECTIVE',
      component: Checkbox,
      data: {
        title: 'Aké ciele chcete dosiahnúť novou webstránkou?',
        subtitle: 'Web sa má chovať ako skúsený obchodník, ktorý využíva ľudské chovanie tak, aby predával...',
        required: false,
        isRequiredDefValue: false,
        showStar: false,
        options: [
          {
            id: 'SALE_INCREASE',
            label: 'Zvýšenie predaja',
            type: 'default',
          },
          {
            id: 'IMPROVE_BRAND_AWARENESS',
            label: 'Zlepšenie povedomia o značke / službách',
            type: 'default',
          },
          {
            id: 'INCREASE_WEBSITE_TRAFFIC',
            label: 'Zvýšenie návštevnosti webu',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'MAIN_INFORMATION',
      component: TextInput,
      data: {
        title: 'Čo by malo ako prvé udrieť do očí návštevníkovi? Aká informácia?',
        subtitle: 'Ukážte, že ste na strane ľudí, ktorí využívajú Vaše služby. Napr.: “Máme najväčší výber, najnižšie ceny, najrýchlejší servis, atď.”',
        required: false,
        useEnterKey: true,
        validationSchema: yup.string().required(),
      },
    },
    {
      id: 'FIRST_CLICK',
      component: TextInput,
      data: {
        title: 'Kam by mal smerovať prvý klik návštevníka?',
        subtitle: 'Hlavná stránka je predurčená, aby z nej návštevník skôr či neskôr odišiel. Kam má smerovať jeho ďalší krok? Napr. na katalóg produktov, články blogu atď.',
        required: false,
        validationFunction: (value) => {
          return value && value.length > 0
        },
      },
    },
  ],
}

export default data