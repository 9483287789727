import React, { Component } from 'react'
import * as  PropTypes from 'prop-types'
import { childValue } from '../briefHelpers'
import Switch from './switch'

class SwitchConditionalRender extends Component {
  constructor(props) {
    super(props)
    this.elRef = React.createRef()
    this.switchValueLast = false
  }

  onSwitchValue(value, calback) {
    const { options } = this.props
    if (this.elRef && this.elRef.current && value) {
      setTimeout(() => { 
        if (this.elRef && this.elRef.current) {
          const input = this.elRef.current.querySelector('input, textarea')
          if (input != null) {
            input.focus()
          }
        }
      }, 0)
    }
  }

  render() {
    const { setValue, value, options } = this.props
    const { conditionalRenderTrue, conditionalRenderFalse } = options
    if (options.required && value) {
      options.labelRight = options.labelRight.replaceAll(':<span class="required">*</span>', '') + ':<span class="required">*</span>'
    } else {
      options.labelRight = options.labelRight.replaceAll(':<span class="required">*</span>', '')
    }

    const [conditionalRenderValue, conditionalRenderSetValue] = childValue('conditionalRender', value, setValue)
    const [switchValue, switchSetValue] = childValue('switchValue', value, setValue)

    const [ConditionalRenderTrue, ConditionalRenderFalse] = [
      conditionalRenderTrue != null ? conditionalRenderTrue.component : (null),
      conditionalRenderFalse != null ? conditionalRenderFalse.component : (null),
    ]

    this.switchValueLast = switchValue
    return (
      <div ref={this.elRef} className={'switch-conditional-render avoid'}>
        <Switch setValue={e => {
          this.onSwitchValue(e, switchSetValue)
          switchSetValue(e)
        }} value={switchValue === true} options={options}/>
        {switchValue ?
          (
            conditionalRenderTrue != null ?
              <ConditionalRenderTrue
                value={conditionalRenderValue}
                setValue={conditionalRenderSetValue}
                options={conditionalRenderTrue != null ? conditionalRenderTrue.data : null}
              /> : (null)
          ) : (
            conditionalRenderFalse != null ?
              <ConditionalRenderFalse
                value={conditionalRenderValue}
                setValue={conditionalRenderSetValue}
                options={conditionalRenderFalse != null ? conditionalRenderFalse.data : null}
              /> : (null)
          )}
      </div>
    )
  }

}

SwitchConditionalRender.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.shape({
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
  }).isRequired,
}


export default SwitchConditionalRender