import TextInput from '../inputs/textInput'
import * as yup from 'yup'
import Checkbox from '../inputs/checkbox'
import React from 'react'

const data = {
  id: 'CONTACT',
  active: true,
  title: 'Zoznámme sa',
  indicatorTitle: 'Kontakt',
  questions: [
    {
      id: 'NAME_AND_SURNAME',
      component: TextInput,
      data: {
        title: 'Meno a prezvisko',
        required: true,
        type: 'text',
        validationSchema: yup.string().required(),
        customErrorMessage: 'Pre pokračovanie v dotazníku vyplňte Vaše meno a priezvisko'
      },
    },
    {
      id: 'COMPANY_NAME',
      component: TextInput,
      data: {
        title: 'Názov spoločnosti',
        required: false,
        type: 'text',
        validationSchema: yup.string().required(),
      },
    },
    {
      id: 'EMAIL',
      component: TextInput,
      data: {
        title: 'Email',
        required: true,
        type: 'email',
        validationSchema: yup.string().email().required(),
        customErrorMessage: 'Pre pokračovanie v dotazníku zadajte Vašu e-mailovú adresu'
      },
    },
    {
      id: 'PHONE_NUMBER',
      component: TextInput,
      data: {
        title: 'Telefónne číslo',
        required: false,
        type: 'text',
        validationFunction: (value) => {
          return value && value.length >= 8
        },
      },
    },
    {
      id: 'GDPR',
      component: ({ value, setValue }) => (
        <>
          <Checkbox setValue={setValue} value={value} options={{
            options: [
              {
                id: 'ACCEPT',
                label: <>Súhlasím so spracovaním osobných údajov</>,
                type: 'default',
              },
            ],
          }
          }/>
        </>
      ),
      data: {
        title: <>Zásady spracúvania osobných údajov (<a href={'/zasady-spracuvania-osobnych-udajov'} target={'_blank'} rel="noreferrer">Prečítať podmienky</a>)</>,
        required: true,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné zaškrtnúť súhlas s podmienkami',
        validationFunction: (value => value && value.selected && value.selected['ACCEPT'] != null),
      },
    },
  ],
}

export default data