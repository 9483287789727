import React, { Component } from 'react'
import QuestionSubmit from './components/questionSubmit'
import QuestionTitle from './components/questionTitle'
import PropTypes from 'prop-types'
import { scrollIntoView } from '../../utilities'
import SectionTitle from './components/sectionTitle'

class Question extends Component {
  constructor(props) {
    super(props)
  //  const { data } = props

    const val = props.value != null ? props.value : null

    this.questionRef = React.createRef()
    this.focusedLastValue = false
    this.setValue = this.setValue.bind(this)
    this.skip = this.skip.bind(this)
    this.submit = this.submit.bind(this)
    this.validate = this.validate.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    const valid = this.validate(val)
    this.state = {
      value: val,
      isValid: valid,
    }
  }

  validate(value) {
    let localValue = value
    if (localValue && typeof localValue === 'string') {
      localValue = localValue.replace(/\s/g, "")
    } else if(localValue && localValue.newInput){
      localValue = localValue.newInput.replace(/\s/g, "")
    }
    
    const { data } = this.props
    if (data.validationSchema != null) {
    
      return data.validationSchema.isValidSync(localValue)
    } else if (data.validationFunction != null) {
      return !!data.validationFunction(localValue, data)
    } else {
      console.error('No validation method specified!')
    }
    return false
  }

  async setValue(value, isValid = false) {
    const { data } = this.props

    let newValue = value
    if (typeof value === 'object') {
      newValue = { ...this.state.value, ...value }
    }
    let valid = isValid

    if (data.validationSchema != null || data.validationFunction != null) {
      valid = this.validate(newValue)
    }

    this.setState({
      value: newValue,
      isValid: valid,
    })
  }

  submit() {
    const { setQuestionValue, id } = this.props
    setQuestionValue(id, this.state.value)
  }

  skip() {
    const { setQuestionValue, id } = this.props
    setQuestionValue(id, null)
    // setQuestionValue(id, 'Nevyplnene')
    // this.setState({
    //   value: 'Nevyplnene',
    // })
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onKeyPress, {passive: true})

    /* const { data, value } = this.props
     if (value != null) {
       if (data.validationSchema != null || data.validationFunction != null) {
         const valid = this.validate(value)
         this.setState({
           isValid: valid,
         })
       }
     }*/
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onKeyPress)
  }

  onKeyPress(event) {
    const { data, focused } = this.props

    if (event.key === 'Enter' &&
      focused === true &&
      (
        !(!(event.shiftKey === false && data.useEnterKey !== false) &&
          !(data.useEnterKey === false && event.shiftKey))
      )
      /* && document.activeElement.tagName !== "TEXTAREA" */
    ) {
      
      event.preventDefault()
      
      setTimeout(() => {
        if (this.state.isValid) {
          this.submit()
        } else {
          if (data.required === false) {
            this.skip()
          }
        }
      }, 0)
      // event.preventDefault()
    }
  }

  render() {
    const { data, component, focused, disabled, number, id, onClick, forceValueUpdate, value, sectionTitle, sectionNumber, showSectionTitle, avoid } = this.props
    const Component = component

    const val = forceValueUpdate ? value : this.state.value

    setTimeout(() => {
      if (this.questionRef && this.questionRef.current) {
        const el = this.questionRef.current
        if (focused && this.focusedLastValue === false) {
          if (typeof document !== 'undefined') {
            if (document.activeElement != null) {
              document.activeElement.blur()
            }

            const input = el.querySelector('input, textarea')
            if (input != null) {
              input.focus()
            } else {
    
              el.focus()
            }
          }
        }

        // Toto musi byt nizsie ako focus lebo inak sa to cancelne
        if (focused) {
          scrollIntoView(el)
        }
        this.focusedLastValue = focused
      }
    }, 0)

      return (
        <>
            {showSectionTitle &&
                <SectionTitle title={sectionTitle} number={sectionNumber}/>
            }
              
       
        <div
            id={id}
            className={`question ${focused ? 'active' : ''} ${disabled ? 'disabled' : ''} ${avoid ? 'question-avoid' : ''}`}
            onClick={focused ? null : onClick}
        >
            <div className="debug-show id">{id}</div>
            <div className={'container d-flex'}>
                <div className={'question-wrapper'}>
                    <QuestionTitle
                    number={number}
                    title={data.title}
                    subtitle={data.subtitle}
                    isRequired={data.required}
                    showStar={data.showStar}
                    />
                    <div ref={this.questionRef}>
                    <Component
                        options={data}
                        setValue={this.setValue}
                        value={val}
                        formValue={null}
                    />
                    </div>
                    {focused && <QuestionSubmit
                    componentType={component}
                    value={val}
                    useEnterKey={data.useEnterKey}
                    isRequired={data.required}
                    toggledIsRequired={data.toggleIsRequired}
                    isRequiredDefValue={data.isRequiredDefValue}
                    isValid={this.state.isValid}
                    onSubmit={this.submit}
                    onSkip={this.skip}
                    customErrorMessage={data.customErrorMessage}
                    />}
                </div>
            </div>
            </div>
        </>
    )
  }
}

Question.defaultProps = {
  avoid: true,
}

Question.propTypes = {
  data: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setQuestionValue: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  value: PropTypes.any,
  forceValueUpdate: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.any,
  sectionNumber: PropTypes.any,
  avoid: PropTypes.bool
}

export default Question
