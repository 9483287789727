import Radio from '../inputs/radio'
import { radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import AreaInput from '../inputs/areaInput'
import SwitchConditionalRender from '../inputs/switchConditionalRender'
import PriceSlider, { PriceSliderValidation } from '../inputs/priceSlider'
import FileUpload from '../inputs/fileUploader'

const data = {
  id: 'PROJECT_SIZE',
  active: true,
  title: 'Rámec',
  indicatorTitle: 'Rámec',
  questions: [
    {
      id: 'DEADLINE',
      component: Radio,
      data: {
        title: 'Ako veľmi Vám termín horí?',
        subtitle: 'Aby sme vedeli čo najlepšie naplánovať časový harmonogram prác.',
        required: true,
        useEnterKey: true,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'ASAP',
            label: 'Už včera bolo neskoro',
            type: 'default',
          },
          {
            id: 'ONE_MONTH',
            label: 'do 1 mesiaca',
            type: 'default',
          },
          {
            id: 'ONE_TO_THREE_MONTHS',
            label: '1 – 3 mesiace',
            type: 'default',
          },
          {
            id: 'THREE_TO_SIX_MONTHS',
            label: '3 – 6 mesiacov',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
      },
      value: null,
    },
    {
      id: 'BUDGET',
      component: PriceSlider,
      data: {
        title: 'Približný rozpočet',
        subtitle: 'Rýchlo, kvalitne a lacno. Ťažko skombinovateľná rovnica. Keď nám ale napíšete koľko máte vyhradené v prasiatku na tento projekt, vieme Vám spraviť ponuku namieru a povedať aké sú reálne možnosti.',
        required: true,
        validationFunction: PriceSliderValidation,
        customErrorMessage: 'Pre pokračovanie v dotazníku je potrebné zvoliť približný rozpočet na projekt'
      },
    },
    {
      id: 'PROJECT_OTHER',
      component: SwitchConditionalRender,
      data: {
        title: 'Máte nejaké ďalšie doplnenia, otázky alebo požiadavky?',
        subtitle: 'Keď Vám v našom dotazníku chýbalo niečo, čo by ste chceli doplniť, môžete to dopísať sem, alebo to preberieme mailom alebo na osobnom stretnutí.',
        required: false,
        isRequiredDefValue: false,
        toggleIsRequired: true,
        useEnterKey: true,
        toggleEnter: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
        conditionalRenderTrue: {
          component: AreaInput,
          data: {
            placeholder: 'Vpíšte sem text',
          },
        },
      },
      value: null,
    },
    {
      id: 'PROJECT_FILES',
      component: SwitchConditionalRender,
      data: {
        title: 'Máte nejaké ďalšie súbory, nákresy, alebo materiály, ktoré by ste chceli poslať spolu s týmto dotazníkom?',
        subtitle: 'Môžete ich pripojiť teraz, ale pokojne aj neskôr počas našej spolupráce.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
        conditionalRenderTrue: {
          component: FileUpload,
        },
      },
      value: null,
    },
    {
      id: 'WHERE_DID_YOU_HEAR_ABOUT_US',
      component: Radio,
      data: {
        title: 'Odkiaľ ste sa o nás dozvedeli?',
        subtitle: 'Je dobré vedieť kto nám robí takú dobrú reklamu...',
        required: true,
        validationFunction: (value, data) => {
          if (!value) return false;
          data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné označiť jednu z možností'
          const { selected } = value;

          if (selected.id === 'OTHER') {
            data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'
          }
          return (selected != null && selected.id !== 'OTHER') || (selected.data)
        },
        options: [
          {
            id: 'PERSONAL_RECOMMENDATION',
            label: 'Osobné odporúčanie',
            type: 'default',
          },
          {
            id: 'GOOGLE',
            label: 'Vyhľadávanie na Googli',
            type: 'default',
          },
          {
            id: 'FACEBOOK',
            label: 'Sociálne siete ako Facebook, IG, Tiktok a pod.',
            type: 'default',
          },
           {
            id: 'ONLINE',
            label: 'Z médií / online konferencií',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
      },
      value: null,
    },
  ],
}

export default data