import React from 'react'
import PropTypes from 'prop-types'

const QuestionTitle = props => {
  const { title, subtitle, isRequired, showStar = true, number } = props
  
  return (
    <div className={'question-title'}>
      <div className='avoid'>
        <span className={'question-number'}>
        {number}
      </span>
      <div>
        <h3>{title} {isRequired && showStar ? <span className={'required'}>*</span>: null}</h3>
        <h4>{subtitle}</h4>
        </div>
        
      </div>
    </div>
  )
}

QuestionTitle.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  isRequired: PropTypes.any,
  showStar: PropTypes.bool,
  number: PropTypes.number
}

export default QuestionTitle
