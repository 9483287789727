import Radio from '../inputs/radio'
import { getQuestionById, radioDefaultValidation, radioDefaultValidationRequiredInput, switchDefaultValidation } from '../briefHelpers'
import Checkbox from '../inputs/checkbox'
import SwitchConditionalRender from '../inputs/switchConditionalRender'
import Switch from '../inputs/switch'
import React from 'react'
import BriefTooltip from '../BriefTooltip'

const data = {
  id: 'OPTIMIZATION',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.website,
  title: 'Optimalizácia',
  indicatorTitle: 'Optimalizácia',
  questions: [
    {
      id: 'SEO',
      component: Radio,
      data: {
        title: 'SEO',
        subtitle: ' Keď už budete mať taký skvelý web, bola by škoda, keby mal nízku návštevnosť…',
        required: false,
        options: [
          {
            id: 'BASIC_SEO',
            label: 'Základné SEO nastavenia',
            type: 'default',
          },
          {
            id: 'ADVANCE_SEO',
            label: 'Pokročilé SEO (Analýza trhu, konkurencie, backlinky, stratégia budovania pozícií, atď.)',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
    },
    {
      id: 'KEYWORDS',
      component: Radio,
      data: {
        title: 'Kľúčové slová',
        subtitle: 'Sú tie čarovné mantry, ktoré k Vám pritiahnu Vašich budúcich zákazníkov.',
        required: false,
        showStar: false,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        options: [
          {
            id: 'OTHER',
            label: 'Napíšeme teraz:',
            type: 'input',
            required: true,
            placeholder: 'Prosím vypíšte ich...',
          },
          {
            id: 'LATER',
            label: 'Dodáme vám neskôr',
            type: 'default',
          },
          {
            id: 'NO',
            label: ' Budeme mať záujem o vypracovanie analýzy kľúčových slov',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidationRequiredInput,
      },
      value: {
        selected: {
          id: null,
          data: {},
        },
      },
    },
    {
      id: 'ANALYTICS',
      component: SwitchConditionalRender,
      data: {
        title: 'Budete chcieť sledovať štatistiky webu? (napr. Google Analytics)',
        subtitle: 'Je dobré vedieť, ako sa Vám darí a kde treba ešte popracovať.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
        conditionalRenderTrue: {
          component: Checkbox,
          data: {
            options: [
              {
                id: 'YES',
                label: <><span>Chceme interaktívne vedieť sledovať správanie užívateľov</span> <BriefTooltip content={'presné miesta na webe, kade sa pohybujú Vaši návštevníci'}>(heatmapy)</BriefTooltip></>,
                type: 'default',
              },
            ],
          },
        },
      },
      value: {
        switchValue: true
      },
    },
    {
      id: 'ADDS_MANAGEMENT',
      component: Switch,
      data: {
        title: 'Nastavenie a spravovanie reklamných PPC kampaní (Google Ads a pod.)',
        subtitle: 'Týmto spôsobom viete takmer okamžite získať nových návštevníkov a potenciálnych zákazníkov.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'ADDS_CREATION',
      component: ({ value, setValue, options }) => (<>
        <Switch value={value} setValue={setValue} options={options} />
       {/*  <div className={'mt-3'}>
          <LinkBtn size={1}>
            <a href="/nase-prace/grafika/">Referencie grafika</a>
          </LinkBtn>
        </div> */}
      </>),
      data: {
        title: 'Budete chcieť pomôcť s vytvorením reklamných materiálov, bannerov a pod.?',
        subtitle: 'Naši šikovní grafici čakajú len na Váš povel. Pokiaľ máte od nás aj balík Branding, môžete sa tešiť na naozaj vymakané podklady pre Vaše kampane.',
        required: false,
        validationFunction: () => true,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'SOC_NET_MANAGEMENT',
      component: Switch,
      data: {
        title: 'Pomoc s vytvorením / spravovaním sociálnych sietí',
        subtitle: 'Táto aktivita prináša veľa výsledkov, ale aj zaberá veľa času. Radi Vám s tým pomôžeme.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'UPDATES',
      component: Radio,
      data: {
        title: 'Budete mať záujem o pravidelný servis a vylepšovanie webu?',
        subtitle: 'Zálohovanie webu, aktualizácie pluginov, kontrola funkčnosti, bezpečnosti, optimalizácia rýchlosti atď.',
        required: true,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'REGULAR',
            label: 'Máme záujem o pravidelný servis a spravovanie webu',
            type: 'default',
            placeholder: 'Prosím vypíšte ich...',
          },
          {
            id: 'CONTINUOUSLY',
            label: 'Nebudeme preventívne udržiavať náš web (Neodporúčame)',
            type: 'default',
          },
         /*  {
            id: 'NO',
            label: 'Iba keď budeme potrebovať',
            type: 'default',
          }, */
        ],
      },
      value: null,
    },
    {
      id: 'DOMAIN',
      component: Radio,
      data: {
        title: 'Doména',
        subtitle: 'Čo sa píše v prehliadači.',
        required: false,
        showStar: false,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'WEB',
            label: 'Máme kúpenú doménu:',
            type: 'input',
            placeholder: 'Napíšte jej www adresu',
          },
          {
            id: 'SELECT_1',
            required: false,
            label: 'Budeme chcieť zaregistrovať novú doménu:',
            placeholder: 'Napíšte jej www adresu',
            type: 'input',
          },
        ],
      },
      value: null,
    },
    {
      id: 'HOSTING',
      component: Radio,
      data: {
        title: 'Hosting',
        subtitle: 'Aj Váš web musí niekde bývať. Hosting predstavuje “úložisko”, kde sa Váš web bude fyzicky nachádzať vo forme súborov.',
        required: false,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'YES',
            label: 'Máme existujúci hosting',
            type: 'default',
          },
          {
            id: 'NO',
            label: 'Potrebujeme zriadiť nový hosting',
            type: 'default',
          },
        ],
      },
      value: null,
    },
    {
      id: 'SECURITY',
      component: Radio,
      data: {
        title: 'Zabezpečenie webu',
        subtitle: 'Internet vie byť niekedy dosť hrozivé miesto. Mimo to, so zlepšeným zabezpečením máte väčšiu šancu umiestniť sa na lepších pozíciách vo vyhľadávaní.',
        required: false,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'STANDARD',
            label: 'Štandardné',
            type: 'default',
          },
          {
            id: 'ADVANCE',
            label: 'Pokročilé zabezpečenie webu',
            type: 'default',
          },
        ],
      },
      value: null,
    }
  ],
}

export default data