import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { mapRange } from '../../../utilities'
import Checkbox from './checkbox'
import { childValue } from '../briefHelpers'

export const PriceSliderValidation = (value) => {
  if (value == null) return false
  const { checkbox, slider } = value
  const isOther = checkbox && checkbox.selected && checkbox.selected['OTHER']
  return !(!isOther || !checkbox.selected['OTHER'].data || checkbox.selected['OTHER'].data.length <= 0) || !(isOther || calculateRealPrice(slider) <= 0)
}

const calculateRealPrice = (sliderPosition) => {
  const price = mapRange(Math.pow(sliderPosition, 2), 0, 10000, 0, 20000)
  return Math.round(price / 100) * 100;
}

class PriceSlider extends Component {
  constructor(props) {
    super(props)
    this.getPrice = this.getPrice.bind(this)
  }

  getPrice() {
    const { value } = this.props
    if (value == null || value.slider == null || value.slider === 0) {
      return 'Potiahnite smerom doprava:'
    }

    const sliderValue = value.slider

    if (sliderValue === 100) {
      return '20K+'
    }

    const roundedPrice = calculateRealPrice(sliderValue);

    if (roundedPrice === 0) {
      return 'Potiahnite smerom doprava:'
    }

    return `${roundedPrice}€`
  }

  render() {
    const { setValue, value } = this.props

    const [sliderValue, sliderSetValue] = childValue('slider', value, setValue)
    const [checkboxValue, checkboxSetValue] = childValue('checkbox', value, setValue)

    const isSliderHidden = checkboxValue && checkboxValue.selected && checkboxValue.selected['OTHER']
    const price = this.getPrice()
    return (
      <div className={'price-slider avoid'}>
        {!isSliderHidden && <div className={'slider'}>
          <div className={'slider-label'}>
            <span className={'label'}>0</span>
            <span className={'price'}>{price}</span>
            <span className={'label'}>20K+</span>
          </div>
          <Slider
            min={0}
            max={100}
            defaultValue={sliderValue != null ? sliderValue : 0}
            vlaue={sliderValue}
            onChange={(val) => {
              sliderSetValue(val)
            }}/>
        </div>}
        <Checkbox setValue={checkboxSetValue} value={checkboxValue} options={{
          options: [
            {
              id: 'OTHER',
              label: 'Iné:',
              type: 'input',
              placeholder: 'Vpíšte sem text',
            },
          ],
        }}/>
      </div>
    )
  }
}

PriceSlider.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any.isRequired,
}


export default PriceSlider
