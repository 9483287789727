import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RadioOptionAreaInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.onChangeInput = this.onChangeInput.bind(this)
  }

  resize(elem) {
    if(!elem) return
    elem.style.height = 'auto';
    elem.style.height = elem.scrollHeight + 25 + 'px';
  }

  onChangeInput(el, enter = false,) {
    const { onChange } = this.props
    let { required } = this.props.options
    
    let localValue = el.value

    if (enter) {
      localValue += '\n'
    }
    const trimedValue = localValue.replace(/\s/g, "")

    if (trimedValue.length === 0) {
      localValue = ''
    }
    
    onChange(localValue)
    this.resize(el)

    if (!required && localValue.length === 0) {
      return this.props.options.useEnterKey = true
    } 
    return this.props.options.useEnterKey = false
  
  }

  componentDidMount() {
    this.resize(this.inputRef.current)
  }

  render() {
    const { label, onChange, selected, data, placeholder } = this.props

    let localLabel = label
    if (selected) {
      localLabel = localLabel.replaceAll('<span class="required">*</span>', '') + '<span class="required">*</span>'
    } else {
      localLabel = localLabel.replaceAll('<span class="required">*</span>', '')
    }

    return (
      <div>
        <div
          className={`radio-option flex-row avoid ${selected ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onChange()
            setTimeout(() => {
              if (this.inputRef && this.inputRef.current) {
                this.inputRef.current.focus()
              }
            }, 0)
          }}>
          <div className={`box`}>
            <i className="la la-check"/>
          </div>
          <div className={'label'} dangerouslySetInnerHTML={{ __html: localLabel }}></div>
        </div>
           {selected && <>
            <div className={'print-display'}>
              {data}
            </div>
            <textarea
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              ref={this.inputRef}
              className={'radio-option-input'}
              type={'text'}
              onChange={event => this.onChangeInput(event.target, false)}
              value={data}
              placeholder={placeholder}
            />
          </>}
      </div>
    )
  }
}

RadioOptionAreaInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  data: PropTypes.any,
  options: PropTypes.any,
  placeholder: PropTypes.string,
}


export default RadioOptionAreaInput