import Radio from '../inputs/radio'
import { getQuestionById, radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import AreaInput from '../inputs/areaInput'
import * as yup from 'yup'
import Switch from '../inputs/switch'
import React from 'react'
import BriefTooltip from '../BriefTooltip'
import TextInput from '../inputs/textInput'

const data = {
  id: 'MOBILE_APP',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.app,
  title: 'Mobilná aplikácia',
  indicatorTitle: 'Appka',
  questions: [
    {
      id: 'APP_PLATFORM',
      component: Radio,
      data: {
        title: 'Na akej platforme si prajete aplikáciu vytvoriť?',
        subtitle: 'Od toho sa odvíjajú konkrétne zariadenia, na ktorých Vaša aplikácia môže bežať.',
        required: true,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'ANDORID',
            label: 'Android',
            type: 'default',
          },
          {
            id: 'IOS',
            label: 'iOS',
            type: 'default',
          },
          {
            id: 'Multiplatforma',
            label: 'JS/Cross-platform',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
      },
    },
    {
      id: 'PROGRAMMING_STACK_APP',
      component: Radio,
      data: {
        title: <>Preferujete konkrétny programovací jazyk / <BriefTooltip content={'Kombinácia programovacích jazykov a metód'}>dev-stack</BriefTooltip>?</>,
        subtitle: 'Niektorí klienti majú jasnú predstavu a vieme im samozrejme vyhovieť.',
        required: false,
        showStar: false,
        useEnterKey: true,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'NO',
            label: 'Nechávame to na Vás',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Áno:',
            type: 'input',
            placeholder: 'Javascript, Objective-C, Swift, Java a pod.',
          },
        ],
      },
      value: {
        selected: {
          id: 'NO',
        },
      },
    },
    {
      id: 'APP_USAGE',
      component: TextInput,
      data: {
        title: 'Napíšte stručný popis toho, na čo bude aplikácia slúžiť.',
        subtitle: 'Napr. sociálna aplikácia, komunikácia, zábava, cestovanie, nakupovanie a pod.',
        required: true,
        useEnterKey: true,
        validationSchema: yup.string().required(),
      },
      value: null,
    },
    {
      id: 'SIMILAR_APP',
      component: Radio,
      data: {
        title: 'Existujú na trhu podobné aplikácie?',
        subtitle: 'Keď máte jasnú predstavu, vieme Vám samozrejme vyhovieť.',
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        required: false,
        useEnterKey: true,
        showStar: false,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'UNKNOWN',
            label: 'Nevieme uviesť',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Napíšeme:',
            placeholder: 'Vpíšte sem text',
            type: 'input',
          },
        ],
      },
      value: null,
    },
    /* TODO: dat prec */
    {
      id: 'APP_DIFFERENCE',
      component: TextInput,
      data: {
        title: 'Čím sa bude Vaša aplikácia líšiť?',
        subtitle: 'Nebuďte zbytočne skromní.',
        required: false,
        useEnterKey: true,
        validationSchema: yup.string().required(),
        placeholder: '',
      },
    },
    {
      id: 'APP_WORKS_ON_TABLETS',
      component: Switch,
      data: {
        title: 'Bude aplikácia fungovať aj na tabletoch?',
        subtitle: 'Aby sme vedeli, či navrhovať aj takéto rozlíšenie.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'APP_LEGACY_OS_VERSIONS',
      component: Switch,
      data: {
        title: 'Požadujete spätnú kompatibilitu s predošlými verziami Androidu / iOS?',
        subtitle: 'Aby sa Vaša aplikácia bezchybne zobrazovala aj na starších zariadeniach.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'APP_OTHER',
      component: Radio,
      data: {
        title: 'Máte iné požiadavky, ktoré neboli zatiaľ spomenuté?',
        required: false,
        useEnterKe: true,
        showStar: false,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        validationFunction: radioDefaultValidation, //TODO
        options: [
          {
            id: 'LATER',
            label: 'Doplníme neskôr',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Áno, chceme dopísať teraz:',
            placeholder: 'Vpíšte sem text',
            type: 'text'
          },
        ],
      },
      value: {
        selected: {
          id: 'LATER',
        },
      },
    },
  ],
}

export default data
    