import { checkboxDefaultValidation, childValue, getQuestionById, noEnterValidation, radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import React from 'react'
import AreaInput from '../inputs/areaInput'
import Radio from '../inputs/radio'
import SliderPicker, { SliderValidation } from '../inputs/sliderPicker'
import Switch from '../inputs/switch'
import SwitchConditionalRender from '../inputs/switchConditionalRender'
import Checkbox from '../inputs/checkbox'
import RadioOptionInput from '../inputs/radioOptionInput'
import RadioOption from '../inputs/radioOption'
import SwitchInput from '../inputs/switchInput'
import BriefTooltip from '../BriefTooltip'
import * as yup from 'yup'

const syncValidation = (value, data) => {
  if (value == null) return false
    data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné označiť jednu z možností'

  if (value.selected && (value.selected.id === 'OTHER' || value.selected.id === 'OTHER_1' || value.selected.id === 'WEB' || value.selected.id === 'WEB_1') || (value.switchInput && !value.textInput)) {
    data.useEnterKey = false
    data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'

    if (value.selected.data && value.selected.data.replace(/\s/g, "").length > 0) {
      if(value.selected.type !== 'text'){
        data.useEnterKey = true
      }
    }
    if (!value.selected.data || value.selected.data === 'undefined') {
      data.required = true
      return false
    }
  }
 
  if (value.switchInput && !value.textInput.replace(/\s/g, "").length) {
    return false
  }

  const childSelected = value.childSelected
  if (childSelected) {
    const selected = childSelected.selected
    if (selected.OTHER && (!selected.OTHER.data || selected.OTHER.data.replace(/\s/g, "").length < 1)) {
      data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'
      return false
    }
  }
  data.useEnterKey = true
  return value && value.selected != null && value.selected.id != null
}

const SyncComponent = ({ value, setValue }) => {

  const [customChildValue, customChildSetValue] = childValue('childSelected', value, setValue)
  const onChange = (id, type, data, required) => {
    if(value.selected.id !== id || typeof data !== 'undefiened'){
      return setValue({
          selected: {
            type,
            id,
            data,
            required
          },
      })
    }
  }
  return (
    <div className={'mb-3 mt-3'}>
      <div className={'radio'}>
        <RadioOptionInput
          label={`Áno, chceme napojiť tento systém:`}
          onChange={(data, sv) => onChange('OTHER', 'input', data, sv)}
          selected={value && value.selected && value.selected.id === 'OTHER'}
          data={value && value.selected && value.selected.data}
          placeholder={'napr. Pohoda, Omega, a pod.'}
        />
        <RadioOption
          label={'Áno, necháme si poradiť s výberom skladového systému'}
          onChange={(data, sv) => onChange('OPTION_1', 'default', data, sv)}
          selected={value && value.selected && value.selected.id === 'OPTION_1'}
          data={value && value.selected && value.selected.data}
        />
        <RadioOption
          label={'Chceme skladový systém na mieru'}
          onChange={(data, sv) => onChange('OPTION_2', 'default', data, sv)}
          selected={value && value.selected && value.selected.id === 'OPTION_2'}
          data={value && value.selected && value.selected.data}
        />
   
        <RadioOption
          label={'Nepotrebujeme riešiť sklady'}
          onChange={(data, sv) => onChange('OPTION_3', 'default', data, sv)}
          selected={value && value.selected && value.selected.id === 'OPTION_3'}
          data={value && value.selected && value.selected.data}
        />
        {value.selected && (value.selected.id === 'OPTION_1' || value.selected.id === 'OPTION_2' || value.selected.id === 'OTHER') &&
          <div className='ml-5 mt-4'>
            <b>Akým spôsobom bude prepojený externý skladový/účtovný systém?</b>
            <Checkbox
              setValue={customChildSetValue}
              value={customChildValue}
              options={{
                options: [
                  {
                    id: 'SELECT_1',
                    label: 'Posielanie objednávok z e-shopu',
                    type: 'default',
                  },
                  {
                    id: 'SELECT_2',
                    label: 'Synchronizácia stavov objednávok',
                    type: 'default',
                  },
                  {
                    id: 'SELECT_3',
                    label: 'Synchronizácia produktov',
                    type: 'default',
                  },
                  {
                    id: 'SELECT_4',
                    label: 'Synchronizácia skladových zásob',
                    type: 'default',
                  },
                  {
                    id: 'SELECT_5',
                    label: 'Synchronizácia cien/dostupnosti tovaru',
                    type: 'default',
                  },
                  {
                    id: 'SELECT_6',
                    label: 'Synchronizácia zákazníkov',
                    type: 'default',
                  },
                  {
                    id: 'OTHER',
                    label: `Iné:`,
                    type: 'input',
                    placeholder: 'Vpíšte sem text'
                  }
                ],
              }} />
            
            <div className='mt-4'>
              <Checkbox
                setValue={customChildSetValue}
                value={customChildValue}
                options={
                  {
                    options: [
                      {
                        id: 'OTHER_1',
                        label: 'Využívame viacero skladov',
                        type: 'default',
                      }
                    ]
                  }
                } 
              />

            </div>
          </div>
        }

        <div className='radio-option'>
          <div className='label mt-3 mb-2'>Chcete dopísať viac informácií ohľadom skladu?</div>
        </div>
        <SwitchInput setValue={setValue} value={value} options={{
            labelRight: `Áno:`,
            labelLeft: 'Zatiaľ nie',
            placeholder: 'Vpíšte sem text',
          }}
        />
      </div>
    </div>
  )
}

const DeliveryOptions = ({ value, setValue }) => {
  return (
    <div className={'radio'}>
      <Checkbox
        setValue={setValue}
        value={value}
        options={{
          options: [
            {
              id: 'MAIN_OTHER',
              label: 'Kuriér:',
              placeholder: 'Napr. GLS, UPS, DPD a pod.',
              type: 'input',
            },
            {
              id: 'MAIN__2',
              label: 'Pošta',
              type: 'default',
            },
            {
              id: 'MAIN_OTHER_1',
              label: 'Zásielkovňa:',
              placeholder: 'Napr. Packeta a pod.',
              type: 'input',
            },
            {
              id: 'MAIN__4',
              label: 'Osobný odber',
              type: 'default',
            },
            {
              id: 'MAIN_OTHER_3',
              label: 'Iné:',
              type: 'input',
              placeholder: 'Vpíšte sem text'
            },
        
          ],
        }}
      />

      <div className='radio-option'>
        <div className='label mt-4'><b>Ďalšie možnosti</b></div>
      </div> 
      <Checkbox
        setValue={setValue}
        value={value}
        options={{
          options: [
            {
              id: 'SELECT_10',
              label: 'Manuálny export objednávok pre kuriéra (XML, CSV a pod.)',
              type: 'default',
            },
            {
              id: 'SELECT_11',
              label: 'Automatické prepojenie cez API pre kuriéra',
              type: 'default',
            },
            {
              id: 'SELECT_12',
              label: 'Správa balíkov a štítkov v administrácii',
              type: 'default',
            },
            {
              id: 'SELECT_13',
              label: 'Cena dopravy závislá od hmotnosti alebo iných faktorov',
              type: 'default',
            },
            {
              id: 'SELECT_14',
              label: 'Doprava zadarmo nad zvolenú sumu alebo iné kritérium',
              type: 'default',
            },
            {
              id: 'SELECT_15',
              label: 'Individuálne povolenia dopravy pre rôzne krajiny',
              type: 'default',
            },
        
          ],
        }}
      />
    </div>
  )
}

const PaymentMethods = ({ value, setValue }) => {
  return (
    <div className={'radio pb-2'}>
      <Checkbox
        setValue={setValue}
        value={value}
        options={{
          options: [
            {
              id: 'MAIN_OTHER',
              label: 'Platobná brána:',
              placeholder: 'Napr. Besteron, Gopay a pod.',
              type: 'input',
            },
            {
              id: 'MAIN_SELECT_1',
              label: 'Kreditná/debetná karta',
              type: 'default',
            },
            {
              id: 'MAIN_SELECT_2',
              label: 'Dobierka',
              type: 'default',
            },
            {
              id: 'MAIN_SELECT_3',
              label: 'Paypal',
              type: 'default',
            },
            {
              id: 'MAIN_SELECT_4',
              label: 'V hotovosti pri osobnom odbere',
              type: 'default',
            },
            {
              id: 'MAIN_SELECT_5',
              label: 'Prevodom',
              type: 'default',
            },
            {
              id: 'MAIN_SELECT_6',
              label: 'Na faktúru',
              type: 'default',
            },
            {
              id: 'MAIN_OTHER_2',
              label: 'Iné:',
              type: 'input',
              placeholder: 'Vpíšte sem text'
            },
        
          ],
        }}
      />

      <div className='radio-option'>
        <div className='label mt-4'><b>Ďalšie možnosti</b></div>
      </div> 
      <Checkbox
        setValue={setValue}
        value={value}
        options={{
          options: [
            {
              id: 'SELECT_7',
              label: 'Manuálne zadanie prijatej platby',
              type: 'default',
            },
            {
              id: 'SELECT_8',
              label: 'Manuálne nastavenie príplatkov podľa druhu dopravy/iných kritérií',
              type: 'default',
            },
            {
              id: 'SELECT_9',
              label: 'Automatické párovanie prijatých platieb, potvrdenie obj. a vystavenie faktúry',
              type: 'default',
            },
            {
              id: 'OTHER_3',
              label: 'Napojenie na splátkový systém:',
              placeholder: 'Vpíšte sem text',
              type: 'input',
            },
          ],
        }}
      />
    </div>
  )
}

const data = {
  id: 'ESHOP',
  active: (formValue) => {
    const active = getQuestionById(formValue, 'PROJECT_TYPE');
    return active.value.website && active.value.websiteType.selected.id === "ESHOP"
  },
  title: 'E-shop',
  indicatorTitle: 'E-shop',
  questions: [
    {
      id: 'ESHOP_1',
      component: Radio,
      data: {
        title: 'Aké sú dôvody k tvorbe nového e-shopu?',
        subtitle: 'Z nejakého dôvodu sme sa tu spoločne stretli.',
        required: true,
        useEnterKey: true,
        options: [
          {
            id: 'OPTION_1',
            label: 'Chceme vyskúšať online predaj, zatiaľ sme nepodnikali vôbec',
            type: 'default',
          },
          {
            id: 'OPTION_2',
            label: 'Máme kamennú prevádzku, chceme k nej pridať e-shop a zvýšiť predaje',
            type: 'default',
          },
          {
            id: 'OPTION_3',
            label: 'S existujúcim e-shopom/dodávateľom nie sme spokojní',
            type: 'default',
          },
          {
            id: 'OPTION_4',
            label: 'Máme úspešný e-shop, chceme ho posunúť ešte o úroveň vyššie',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            required: true,
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_2',
      component: Radio,
      data: {
        title: 'Na aké trhy je e-shop určený?',
        subtitle: 'Môžete uviesť aj ľubovoľné kombinácie jazykov, mien a pod.',
        required: true,
        useEnterKey: false,
        options: [
          {
            id: 'OPTION_1',
            label: 'Slovensko (jazyk SK, mena EUR, krajina dodania SVK)',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Napíšte:',
            type: 'text',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_3',
      component: AreaInput,
      data: {
        title: 'Vymenujte produkty prípadne kategórie, ktoré sú pre váš e-shop najdôležitejšie',
        subtitle: 'Na aký sortiment sa chcete najviac zamerať? Napr. taký, ktorý je najziskovejší, ktorý máte ako jediný na trhu atď.',
        required: false,
        validationFunction: noEnterValidation,
      },
    },
    {
      id: 'ESHOP_4',
      component: AreaInput,
      data: {
        title: 'Podľa čoho sa vaši zákazníci rozhodujú pri výbere?',
        subtitle: 'Záleží hlavne na cene? Množstve sortimentu? Pozáručnom servise?',
        required: false,
        validationFunction: noEnterValidation,
      },
    },
    {
      id: 'ESHOP_5',
      component: SliderPicker,
      data: {
        title: 'Koľko produktov (max.) bude obsahovať nový e-shop?',
        subtitle: 'Pokúste sa odhadnúť všetky produkty vrátane variantov. Tento údaj je dôležitý pre správny výber technológie.',
        required: true,
        validationFunction: SliderValidation,
      },
    },
    {
      id: 'ESHOP_6',
      component: Switch,
      data: {
        title: 'Budú existovať varianty ako napr. rôzne veľkosti, farby jedného produktu?',
        subtitle: 'Napr. oblečenie, kde sa vyberá veľkosť a niekedy aj farba, alebo drogéria, kde si zákazník volí objem balenia.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: false,
    },
    {
      id: 'ESHOP_7',
      component: SwitchConditionalRender,
      data: {
        title: 'Želáte si filtrovanie produktov?',
        subtitle: 'Je vhodné pri e-shopoch, ktoré majú veľké množstvo produktov.',
        required: false,
        isRequiredDefValue: false,
        toggleIsRequired: true,
        useEnterKey: true,
        toggleEnter: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
        conditionalRenderTrue: {
          component: AreaInput,
          data: {
            placeholder: 'Napr. filtrovanie podľa ceny, značky, farby, veľkosti, vlastností a pod.',
          },
        },
      },
      value: {
        switchValue: true
      },
    },
    /*  {
       id: 'ESHOP_8',
       component: Radio,
       data: {
         title: 'Budete chcieť využívať funkciu súvisiace produkty?',
         required: false,
         useEnterKey: true,
         options: [
           {
             id: 'OPTION_1',
             label: 'Áno, budem ich zadávať manuálne v administrácii',
             type: 'default',
           },
           {
             id: 'OPTION_2',
             label: 'Áno, podľa stanovenej logiky automaticky',
             type: 'default',
           },
           {
             id: 'OPTION_3',
             label: 'Nie',
             type: 'default',
           },
         ],
         validationFunction: radioDefaultValidation,
       },
       value: {
         selected: {},
       },
     }, */
    /* TODO */
    {
      id: 'ESHOP_10',
      component: SyncComponent,
      data: {
        title: 'Budete využívať prepojenie a synchronizáciu na skladový systém?',
        required: true,
        useEnterKey: true,
        validationFunction: syncValidation,
      },
      value: {
        selected: {},
        switchInput: false,
      },
    },
    {
      id: 'ESHOP_11',
      component: Radio,
      data: {
        title: 'Bude e-shop vystavovať faktúry?',
        isRequiredDefValue: false,
        required: true,
        useEnterKey: true,
        options: [
          {
            id: 'OTHER',
            label: 'Áno, chceme napojiť tento systém:',
            type: 'input',
            placeholder: 'napr. Superfaktúra a pod.'
          },
          {
            id: 'OPTION_2',
            label: 'Áno, necháme si poradiť s výberom fakturačného systému',
            type: 'default',
          },
          {
            id: 'OPTION_3',
            label: 'Chceme fakturačný systém na mieru',
            type: 'default',
          },
          {
            id: 'OPTION_4',
            label: 'Nie, faktúry budeme vystavovať vo vlastnej réžii',
            type: 'default',
          },
          {
            id: 'OTHER_1',
            label: 'Chcete dopísať viac informácií ohľadom faktúr?',
            type: 'switchText',
            options: {
              labelRight: 'Áno:',
              labelLeft: 'Zatiaľ nie',
              placeholder: 'Vpíšte sem text'
            },
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {},
        switchInput: false,
      },
    },
    {
      id: 'ESHOP_12',
      component: DeliveryOptions,
      data: {
        title: 'Možnosti doručenia',
        required: true,
        useEnterKey: true,
        hasChildren: true,
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_13',
      component: PaymentMethods,
      data: {
        title: 'Spôsoby platby',
        required: true,
        useEnterKey: true,
        hasChildren: true,
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_14',
      component: Radio,
      data: {
        title: 'Nahrávanie produktov',
        subtitle: 'Budete vkladať produkty do e-shopu manuálne alebo hromadne importovať?',
        required: true,
        useEnterKey: true,
        options: [
          {
            id: 'OPTION_1',
            label: 'Manuálne vkladanie produktov cez administráciu',
            type: 'default',
          },
          {
            id: 'OPTION_2',
            label: 'Jednorazový import z pôvodného eshopu/iného zdroja (XLS, XML, CSV)',
            type: 'default',
          },
          {
            id: 'OPTION_3',
            label: 'Automatická synchronizácia produktov so skladovým/ERP systémom',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_15',
      component: Checkbox,
      data: {
        title: 'Funkcie / Produkty',
        subtitle: 'Nechajte sa inšpirovať tým, čo všetko dokážeme. Toto sú niektoré z doplnkových funkcií, ktoré ponúkame.',
        required: false,
        useEnterKey: true,
        twoCol: true,
        options: [
          {
            id: 'SELECT_1',
            label: 'Fulltext vyhľadávanie/našepkávač',
            type: 'default',
          },
          {
            id: 'SELECT_10',
            label: 'SMS notifikácie o stavoch objednávky',
            type: 'default',
          },
          {
            id: 'SELECT_2',
            label: 'Súvisiace/odporúčané produkty',
            type: 'default',
          },
          {
            id: 'SELECT_11',
            label: 'Platba cez QR kód',
            type: 'default',
          },
          {
            id: 'SELECT_3',
            label: 'Hodnotenie/komentáre produktov',
            type: 'default',
          },
          {
            id: 'SELECT_12',
            label: 'Značky a výrobcovia',
            type: 'default',
          },
          {
            id: 'SELECT_4',
            label: 'Porovnanie produktov',
            type: 'default',
          },
          {
            id: 'SELECT_13',
            label: 'Digitálne produkty',
            type: 'default',
          },
          {
            id: 'SELECT_5',
            label: 'Sety/balíky produktov',
            type: 'default',
          },
          {
            id: 'SELECT_14',
            label: 'Sledovanie zásielky',
            type: 'default',
          },
          {
            id: 'SELECT_6',
            label: 'Konfigurátor produktov',
            type: 'default',
          },
          {
            id: 'SELECT_15',
            label: 'Vodoznak na fotky',
            type: 'default',
          },
          {
            id: 'SELECT_7',
            label: 'Jednostránkový nákup',
            type: 'default',
          },
          {
            id: 'SELECT_16',
            label: 'História/vývoj cien',
            type: 'default',
          },
          {
            id: 'SELECT_8',
            label: 'MegaMenu',
            type: 'default',
          },
          {
            id: 'SELECT_17',
            label: 'Nekonečný scroll produktov',
            type: 'default',
          },
          {
            id: 'SELECT_9',
            label: 'Nákup bez registrácie/automatická reg.',
            type: 'default',
          },
          {
            id: 'SELECT_18',
            label: 'Súbory na stiahnutie k produktu',
            type: 'default',
          }
        
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_16',
      component: Checkbox,
      data: {
        title: 'Funkcie / Podpora predaja',
        subtitle: 'Nechajte sa inšpirovať tým, čo všetko dokážeme. Toto sú niektoré z doplnkových funkcií, ktoré ponúkame.',
        required: false,
        useEnterKey: true,
        twoCol: true,
        options: [
          {
            id: 'SELECT_1',
            label: 'Top produkty / Odporúčané / Najpredávanejšie',
            type: 'default',
          },
          {
            id: 'SELECT_9',
            label: 'Zľava na prvý nákup',
            type: 'default',
          },
          {
            id: 'SELECT_2',
            label: 'Zľavové kódy / kupóny',
            type: 'default',
          },
          {
            id: 'SELECT_10',
            label: <><BriefTooltip content={'E-mail o nedokončenej objednávke s uloženým URL košíka, prípadne okamžitou zľavou keď dokončí nákup v časovom limite'}>Opustený košík</BriefTooltip></>,
            type: 'default',
          },
          {
            id: 'SELECT_3',
            label: 'Darčekové poukážky',
            type: 'default',
          },
          {
            id: 'SELECT_11',
            label: <><BriefTooltip content={'Upozornenie na aktuálnu znovudostupnosť alebo zmenu ceny produktu'}>Watchdog</BriefTooltip></>,
            type: 'default',
          },
          {
            id: 'SELECT_4',
            label: 'Vernostný systém / kredity na ďalší nákup al. zľavu',
            type: 'default',
          },
          {
            id: 'SELECT_12',
            label: <><BriefTooltip content={'Ukladanie zoznamu produktov na neskorší nákup'}>Wishlist</BriefTooltip></>,
            type: 'default',
          },
          {
            id: 'SELECT_5',
            label: 'Naposledy navštívené produkty',
            type: 'default',
          },
          {
            id: 'SELECT_13',
            label: 'Darček k objednávke',
            type: 'default',
          },
          {
            id: 'SELECT_6',
            label: 'Výpredaj',
            type: 'default',
          },
          {
            id: 'SELECT_14',
            label: 'Doplnky k nákupu',
            type: 'default',
          },
          {
            id: 'SELECT_7',
            label: 'Tovar 1 klik do Košíka',
            type: 'default',
          },
          {
            id: 'SELECT_15',
            label: <><BriefTooltip content={'Možnosť pridania časovo obmedzenej akcie na produkty'}>Last minute</BriefTooltip></>,
            type: 'default',
          },
          {
            id: 'SELECT_8',
            label: 'Odpočet do dopravy zdarma',
            type: 'default',
          },
          {
            id: 'SELECT_16',
            label: 'Koľko ľudí si prezerá práve produkt?',
            type: 'default',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_17',
      component: Checkbox,
      data: {
        title: 'Funkcie / Marketing',
        subtitle: 'Nechajte sa inšpirovať tým, čo všetko dokážeme. Toto sú niektoré z doplnkových funkcií, ktoré ponúkame.',
        required: false,
        isRequiredDefValue: false,
        useEnterKey: true,
        twoCol: true,
        options: [
          {
            id: 'OTHER',
            label: 'Porovnávač cien:',
            placeholder: 'napr. Heureka, Pricemania, Najnakup…',
            type: 'input',
          },
          {
            id: 'SELECT_8',
            label: 'Vernostný program / affiliate',
            type: 'default',
          },
          {
            id: 'SELECT_1',
            label: 'Automatické generovanie feedov',
            type: 'default',
          },
          {
            id: 'SELECT_9',
            label: 'Dropshipping',
            type: 'default',
          },
          {
            id: 'SELECT_2',
            label: 'Google remarketing feed',
            type: 'default',
          },
          {
            id: 'SELECT_10',
            label: 'Zdieľanie produktov na sociálne siete',
            type: 'default',
          },
          {
            id: 'SELECT_3',
            label: 'Google Enhanced ecommerce tracking + datalayer',
            type: 'default',
          },
          {
            id: 'SELECT_11',
            label: 'Dotazník spokojnosti',
            type: 'default',
          },
          {
            id: 'SELECT_4',
            label: 'Facebook remarketing feed',
            type: 'default',
          },
          {
            id: 'SELECT_12',
            label: 'Otázka k produktu / Poradňa',
            type: 'default',
          },
          {
            id: 'SELECT_5',
            label: 'Integrácia Rich snippets',
            type: 'default',
          },
          {
            id: 'SELECT_13',
            label: 'Newsletter',
            type: 'default',
          },
          {
            id: 'SELECT_6',
            label: <>Export na predajné miesta/<BriefTooltip content={'Mall, Favi, Glami, Amazon, eBay a ďalšie'}>marketplaces</BriefTooltip></>,
            type: 'default',
          },
          {
            id: 'SELECT_14',
            label: 'On-line chat',
            type: 'default',
          },
          {
            id: 'SELECT_7',
            label: 'Luigisbox integrácia',
            type: 'default',
          },
          {
            id: 'SELECT_15',
            label: <><BriefTooltip content={'Umelá inteligencia poháňaná Chat-GPT'}>Chatbot (AI)</BriefTooltip></>,
            type: 'default',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_18',
      component: Checkbox,
      data: {
        title: 'Funkcie / Administračné',
        subtitle: 'Nechajte sa inšpirovať tým, čo všetko dokážeme. Toto sú niektoré z doplnkových funkcií, ktoré ponúkame.',
        required: false,
        useEnterKey: true,
        twoCol: true,
        options: [
          {
            id: 'SELECT_1',
            label: 'Pokročilý dashboard/štatistiky na mieru',
            type: 'default',
          },
          {
            id: 'SELECT_10',
            label: 'Prenos starých účtov/objednávok na nový e-shop',
            type: 'default',
          },
          {
            id: 'SELECT_2',
            label: 'Viacero správcov/rolí e-shopu',
            type: 'default',
          },
          {
            id: 'SELECT_11',
            label: 'Export produktov/objednávok',
            type: 'default',
          },
          {
            id: 'SELECT_3',
            label: 'Registrácia/prihlásenie cez Facebook',
            type: 'default',
          },
          {
            id: 'SELECT_12',
            label: 'Hromadná tlač štítkov z administrácie',
            type: 'default',
          },
          {
            id: 'SELECT_4',
            label: 'Registrácia/prihlásenie cez Google konto',
            type: 'default',
          },
          {
            id: 'SELECT_13',
            label: 'Automatické expedovanie kuriérom',
            type: 'default',
          },
          {
            id: 'SELECT_5',
            label: 'Klientska sekcia/História objednávok',
            type: 'default',
          },
          {
            id: 'SELECT_14',
            label: 'Automatická zmena kurzu mien',
            type: 'default',
          },
          {
            id: 'SELECT_6',
            label: 'Veľkoobchodné a maloobchodné ceny',
            type: 'default',
          },
          {
            id: 'SELECT_15',
            label: 'Online reklamácia',
            type: 'default',
          },
          {
            id: 'SELECT_7',
            label: 'Skupiny zákazníkov/individuálne zľavy',
            type: 'default',
          },
          {
            id: 'SELECT_16',
            label: 'Správca súborov na mieru',
            type: 'default',
          },
          {
            id: 'SELECT_8',
            label: 'B2B predaj',
            type: 'default',
          },
          {
            id: 'SELECT_17',
            label: 'Scrapper a parser dát z konkurenčných e-shopov',
            type: 'default',
          },
          {
            id: 'SELECT_9',
            label: 'Import feedov od dodávateľov',
            type: 'default',
          },
          {
            id: 'SELECT_18',
            label: <><BriefTooltip content={'Správa viacerých e-shopov pod jedným účtom'}>E-shop bude fungovať ako Multistore </BriefTooltip></>,
            type: 'default',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'ESHOP_19',
      component: AreaInput,
      data: {
        required: false,
        useEnterKey: true,
        title: 'Chýba vám niečo?',
        subtitle: 'Ak ste nenašli funkcie o ktoré máte záujem, napíšte nám ich sem a zapracujeme ich. Vieme naprogramovať doslova všetko.',
        type: 'email',
        validationSchema: yup.string().required(),
        customErrorMessage: 'Ak ste nenašli funkcie o ktoré máte záujem, napíšte nám ich sem a zapracujeme ich. Vieme naprogramovať doslova všetko.'
      },
    },
    {
      id: 'ESHOP_20',
      component: Switch,
      data: {
        required: false,
        useEnterKey: true,
        title: 'Potrebujete pomôcť s právnymi povinnosťami?',
        subtitle: 'VOP, GDPR, Reklamačný poriadok, a pod.?',
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno, využijeme',
        labelLeft: 'Máme zabezpečené',
      },
      value: false,
    },
  ],
}

export default data