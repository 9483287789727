import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = props => {
  const { title, number } = props
  return (
    <h2 className={'container'}>{`${number}. ${title}`}</h2>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
}

export default SectionTitle
