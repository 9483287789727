import * as yup from 'yup'
import AreaInput from '../inputs/areaInput'
import Radio from '../inputs/radio'
import Checkbox from '../inputs/checkbox'
import { checkboxDefaultValidation, listDefaultValidation, radioDefaultValidation } from '../briefHelpers'
import List from '../inputs/list'
import CurrentWebsite, { CurrentWebsiteValidation } from '../inputs/currentWebsite'
import TextInput from '../inputs/textInput'

const data = {
  id: 'MARKET_POSITION',
  active: true,
  title: 'Postavenie na trhu',
  indicatorTitle: 'Trh',
  questions: [
    {
      id: 'COMPANY_TYPE',
      component: TextInput,
      data: {
        useEnterKey: true,
        title: 'Čím sa zaoberá Vaša spoločnosť?',
        subtitle: 'Napr. predaj elektroniky, bazár, sprostredkovanie práce, výroba nábytku a pod.',
        required: true,
        validationSchema: yup.string().required(),
      },
    },
    {
      id: 'COMPANY_MAIN_PRODUCT',
      component: TextInput,
      data: {
        useEnterKey: true,
        title: 'Aký je kľúčový produkt alebo služba, ktorú poskytujete?',
        subtitle: 'Čo je základná a najdôležitejšia vec, ktorú ponúkate a napadne Vám do 3 sekúnd?',
        required: true,

        validationSchema: yup.string().required(),
      },
    },
    {
      id: 'COMPANY_COMPETITION',
      component: List,
      data: {
        useEnterKey: true,
        title: 'Ktoré firmy predstavujú Vašu konkurenciu?',
        subtitle: 'Napíšte nám ich názvy alebo webové adresy a pokúsime sa ich predbehnúť.',
        required: false,
        validationFunction: listDefaultValidation,
      },
    },
    {
      id: 'COMPANY_COMPETITION_ADVANTAGES',
      component: List,
      data: {
        useEnterKey: true,
        title: 'Aké sú Vaše konkurenčné výhody?',
        subtitle: 'Čím sa odlišujete? Prečo by si mal zákazník vybrať práve Vás?',
        required: false,
        validationFunction: listDefaultValidation,
      },
    },
    {
      id: 'HOW_CLIENTS_FIND_YOU',
      component: Checkbox,
      data: {
        title: 'Odkiaľ sa ľudia o Vás väčšinou dozvedia?',
        showStar: false,
        isRequiredDefValue: false,
        subtitle: 'Je dobré vedieť odkiaľ prichádzajú, aby sme ich mohli zavolať viac.',
        required: false,
        useEnterKey: true,
        options: [
          {
            id: 'GOOGLE',
            label: 'Z vyhľadávania na Googli a pod.',
            type: 'default',
          },
          {
            id: 'INTERNET',
            label: 'Z platenej reklamy na internete',
            type: 'default',
          },
          {
            id: 'FACEBOOK',
            label: 'Z Facebooku či iných sociálnych sietí',
            type: 'default',
          },
          {
            id: 'PERSONAL_RECOMMENDATIONS',
            label: 'Osobné odporúčania',
            type: 'default',
          },
          {
            id: 'SALES_REPRESENTATIVE',
            label: 'Od obchodného zástupcu',
            type: 'default',
          },
          {
            id: 'MEDIA',
            label: 'Z iných médií, rádio, noviny, atď.',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      value: {
        selected: {},
      },
    },
    {
      id: 'WEB_IMPORTANCE',
      component: Radio,
      data: {
        title: 'Ako je pre Vás web dôležitý?',
        required: false,
        options: [
          {
            id: 'IMPORTANT',
            label: 'Väčšinu zákazníkov získavame prostredníctvom webu',
            type: 'default',
          },
          {
            id: 'SEMI_IMPORTANT',
            label: 'Web je prostriedok pre rast firmy, nie nutnosť na prežitie',
            type: 'default',
          },
          {
            id: 'UNIMPORTANT',
            label: 'Web je len doplnkový nástroj - vizitka',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
    },
    {
      id: 'TARGET_AUDIENCE',
      component: AreaInput,
      data: {
        title: 'Kto je Vaša cieľová skupina? Popíšte typického klienta:',
        useEnterKey: true,
        subtitle: 'Vek, pohlavie, zamestnanie, záujmy a pod.',
        required: false,
        validationSchema: yup.string().required(),
      },
    },
    {
      id: 'USER_RE_ENGAGEMENT',
      component: Radio,
      data: {
        title: 'Návštevník Vášho webu sa:',
        required: false,
        options: [
          {
            id: 'RETURNING',
            label: 'Na web opakovanie vracia',
            type: 'default',
          },
          {
            id: 'ONCE',
            label: 'Navštívi web väčšinou len jednorazovo',
            type: 'default',
          },
          {
            id: 'UNKNOWN',
            label: 'Neviem',
            type: 'default',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
    },
    {
      id: 'CURRENT_WEBSITE',
      component: CurrentWebsite,
      data: {
        useEnterKey: true,
        showStar: false,
        title: 'Máte existujúcu webstránku?',
        subtitle: 'Ak áno, skvelé! Novú urobíme ešte lepšiu!',
        required: false,
        customErrorMessage: 'Pre pokračovanie zadajte adresu vašej stránky',
        validationFunction: CurrentWebsiteValidation,
      },
    }
  ],
}

export default data