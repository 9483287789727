/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react'
import BriefLayout from './briefLayout'
import BriefSection from './briefSection'
import contact from './data/contact'
import eshop from './data/eshop'
import project from './data/project'
import marketPosition from './data/marketPosition'
import objectives from './data/objectives'
import content from './data/content'
import design from './data/design'
import logoAndBranding from './data/logoAndBranding'
import programming from './data/programming'
import mobileApp from './data/mobileApp'
import optimization from './data/optimization'
import projectSize from './data/projectSize'
import briefApi from './briefApi'
import { getQuestionById } from './briefHelpers'
import PageTitle from '../pageTitle'
//import { throttle } from 'lodash'
import HoverBtn from '../HoverBtn'

const data = {
  sections: [
    contact,
    project,
    marketPosition,
    objectives,
    logoAndBranding,
    design,
    content,
    programming,
    eshop,
    mobileApp,
    optimization,
    projectSize,
  ],
}

const initData = {
  formValue: data,
  focusedQuestion: 0,
  focusedQuestionMax: 0,
  forceValueUpdate: false,
  isSubmitted: false,
  formReady: false,
  isSubmittedSuccessfully: false,
  submitError: '',
}


class Brief extends PureComponent {
  constructor(props) {
    super(props)

    this.cache = this.loadCacheFromLs()

    this.state = {
      ...initData,
      formReady: this.cache == null,
    }

    this.setSectionValue = this.setSectionValue.bind(this)
    this.setFocusedQuestion = this.setFocusedQuestion.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.applyCache = this.applyCache.bind(this)
    this.discardCache = this.discardCache.bind(this)
    //this.onWheelThrotled = throttle(this.onWheel.bind(this), 250, { leading: true, trailing: false });

    this.afterSubmitRef = React.createRef()

    if (typeof window != 'undefined') {
      window.SET_BRIEF_STATE = (formData) => {
        const newState = {
          ...this.getStateFromFlatValues(
            initData,
            formData,
            0,
            0,
          ),
          forceValueUpdate: true,
          formReady: true,
        }
        this.setState(newState)
        this.forceUpdate()
      }
    }
  }


 /*  onWheel(e) {
    e.preventDefault();
    const i = this.state.focusedQuestion + (e.deltaY > 0 ? 1 : -1);
    if (i >= 0 && i <= this.state.focusedQuestionMax && Math.abs(e.deltaY) > 50) {
      this.setFocusedQuestion(i);
    }
  } */

scrollStop(e) {
    if (e.code == "Space" && e.target.tagName.toUpperCase() !== 'INPUT' && e.target.tagName.toUpperCase() !== 'TEXTAREA') {
      e.preventDefault();
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.scrollStop)
    //  window.addEventListener('wheel', this.onWheelThrotled, { passive: true });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.scrollStop)
    //  window.removeEventListener('wheel', this.onWheelThrotled);
    }
  }

  applyCache() {
    briefApi.sessionId = this.cache.sessionId
    const cachedFormData = this.getStateFromFlatValues(
      initData,
      this.cache.data,
      this.cache.focusedQuestion,
      this.cache.focusedQuestionMax,
    )
    this.setState({
      ...cachedFormData,
      formReady: true,
    })
  }

  discardCache() {
    briefApi.sessionId = null
    this.setState({
      formReady: true,
    })
  }

  loadCacheFromLs() {
    // let cachedFormData = initData
    if (typeof window != 'undefined') {
      const dataStr = localStorage.getItem('narative-brief-cache')
      if (!(dataStr == null || dataStr.length === 0)) {
        const cache = JSON.parse(dataStr)
        if (cache.data != null) {
          return cache
        }
      }
    }
    return null
  }

  async onSubmit() {
    if (this.state.isSubmitted) return
    // const sections = this.getActiveSections()
    // const lastSection = sections[sections.length - 1]
    // const lastQuestion = this.getActiveQuestions(lastSection.questions);
    this.setState({
      isSubmitted: true,
    })

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'briefFormSubmit',
      });
    }

    if (briefApi.sessionId == null) {
      this.setState({
        isSubmittedSuccessfully: false,
        submitError: 'Dotazník sa nepodarilo odoslať. Skúste to prosím neskôr (Error 2)',
      })
    }

    this.setState({
      isSubmitted: true,
    })


    const response = await briefApi.submit()

    if (response == null) {
      this.setState({
        isSubmittedSuccessfully: false,
        submitError: 'Dotazník sa nepodarilo odoslať. Skúste to prosím neskôr (Error 1)',
      })
      return
    }
    this.setState({
      isSubmittedSuccessfully: true,
    })
    if (this.afterSubmitRef && this.afterSubmitRef.current) {
      localStorage.removeItem('narative-brief-cache')
      //   scrollIntoView(this.afterSubmitRef.current)
    }
  }

  getStateFromFlatValues(oldState, flatValues, focusedQuestion, focusedQuestionMax) {
    return {
      ...oldState,
      focusedQuestion: focusedQuestion,
      focusedQuestionMax: focusedQuestionMax,
      formValue: {
        sections: oldState.formValue.sections.map((section) => {
          return {
            ...section,
            questions: section.questions.map((question) => {
              if (flatValues.hasOwnProperty(question.id)) {
                return {
                  ...question,
                  value: flatValues[question.id],
                }
              }
              return question
            }),
          }
        }),
      },
    }
  }

  getFlatFromValue(formValue) {
    return formValue.sections.reduce((acc, curSection) => {
      const questions = curSection.questions.reduce((qAcc, curQuestion) => {
        return { ...qAcc, [curQuestion.id]: curQuestion.value }
      }, {})
      return { ...acc, ...questions }
    }, {})
  }
  
  

  saveToLocalStorage(newFormValue) {
    if (typeof window != 'undefined') {

      const currentDate = new Intl.DateTimeFormat('sk-SK', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }).format(new Date())

      const flatFormValues = this.getFlatFromValue(newFormValue)
      const data = {
        time: currentDate,
        focusedQuestion: this.state.focusedQuestion,
        focusedQuestionMax: this.state.focusedQuestionMax,
        sessionId: briefApi.sessionId,
        data: flatFormValues,
      }
      localStorage.setItem('narative-brief-cache', JSON.stringify(data))
    }
  }

  async saveToServer(newFormValue) {
    const flatFormValues = this.getFlatFromValue(newFormValue)
    const emailQuestion = getQuestionById(newFormValue, 'EMAIL')
    const nameQuestion = getQuestionById(newFormValue, 'COMPANY_NAME')
    const gdprQuestion = getQuestionById(newFormValue, 'GDPR')

    if (nameQuestion.value && !emailQuestion.value) {
       if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'BriefFormStart',
          });
        }
    }
    
    if (emailQuestion.value != null &&
      gdprQuestion &&
      gdprQuestion.value &&
      gdprQuestion.value.selected &&
      gdprQuestion.value.selected['ACCEPT'] != null) {
      briefApi.email = emailQuestion.value
      briefApi.save(flatFormValues)
    }
  }

  setSectionValue(sectionValue) {
    console.log('sectionValue', sectionValue)
    const newFormValue = {
      ...this.state.formValue,
      sections: this.state.formValue.sections.map((section) => {
        if (section.id === sectionValue.id) {
          return sectionValue
        }
        return section
      }),
    }

    this.setState({
      formValue: newFormValue,
    })
    this.saveToLocalStorage(newFormValue)
    this.saveToServer(newFormValue)
  }

  setFocusedQuestion(absoluteId, questionId) {
    this.setState({
      focusedQuestion: absoluteId,
      focusedQuestionMax: absoluteId > this.state.focusedQuestionMax ? absoluteId : this.state.focusedQuestionMax,
    })
  }

  getActiveSections() {
    // Activate defaut sections after point 7 - categories
    const types = this.state.formValue.sections[1].questions[1].value
    const isActiveType = types.app || types.website || types.design
    return this.state.formValue.sections.filter((section, index) => {
      const { active } = section
      switch (typeof active) {
        case 'boolean':
          return active && (isActiveType || index < 2)
        case 'function':
          return !!active(this.state.formValue)
        default:
          return active == null
      }
    })
  }

  getActiveQuestions(questions) {
    return questions.filter((section) => {
      const { active } = section
      switch (typeof active) {
        case 'boolean':
          return active
        case 'function':
          return !!active(this.state.formValue)
        default:
          return active == null
      }
    })
  }


  render() {
    if (typeof document === 'undefined') return (null)

    let absoluteId = 0
    const sections = this.getActiveSections()
    const types = sections[1].questions[1].value
    const isActiveType = types.app || types.website || types.design
    const lastQuestion = getQuestionById(this.state.formValue, 'WHERE_DID_YOU_HEAR_ABOUT_US')
    const showSubmitButton = lastQuestion && lastQuestion.value && lastQuestion.value.selected && lastQuestion.value.selected.id

    // Vypocita currentStep
    let currentStep = 0
    let i = this.state.focusedQuestion
    let allQuestionsCount = 0;
    for (let index = 0; index < sections.length; index++) {
      allQuestionsCount += sections[index].questions.length
      
    }
    sections.some((section, index) => {
      i -= section.questions.length
      if (i < 0) {
        currentStep = index
        return true
      }
      return false
    })

    currentStep += 1

    if (showSubmitButton) {
      currentStep = sections.length
    }
    let sectionsLength = sections.length;

    if (!isActiveType) {
      sectionsLength = currentStep = 1
    }

    return (
      <BriefLayout
        key={2}
        steps={sectionsLength}
        currentStep={currentStep}
        hideIndicator={this.state.isSubmittedSuccessfully || !this.state.formReady || currentStep < 2}
        sections={sections}
        focusedQuestion={this.state.focusedQuestion}
        allQuestionsCount={allQuestionsCount}
      >

        {this.state.formReady}
        <div className="container">
          <PageTitle seoTitle={'Dotazník - Narative.sk'} />
        </div>

        <div id="brief-content">
          {(this.cache != null &&
            this.state.formReady === false &&
            this.state.isSubmittedSuccessfully === false) ? <section key={1} id={'restoreFromLS'} data-text={'1'}>
            <div className={'container'}>
              <h1>Chcete pokračovať v dotazníku ?</h1>
              <h2>Našla sa neodoslaná verzia formuláru z {this.cache.time}</h2>

              <HoverBtn className="new pp-4 mr-4" handleClick={this.applyCache}>
                <div>Pokračovať</div>
              </HoverBtn>
              <button className={'pdf btn btn-hover mr-0'} onClick={this.discardCache}><div>Chcem vyplniť nový dotazník</div></button>
            </div>
          </section> : (null)}

          {(this.state.formReady === true && this.state.isSubmittedSuccessfully === false) && <div>
            <div className="container d-print-none">
              <PageTitle title={'Pomôžeme Vám so zadaním?'} seoTitle="Dotazník - Narative.sk" />
              <small className={'d-md-none'}>
                V prípade otázok nám zavolajte na <a className='link-danger' href='tel:+421918040888'>0918 040 888</a> alebo napíšte na <a className='link-danger' href='mailto:info@narative.sk'>info@narative.sk</a>
                <br/>
                <br/>
              </small>
             
              <div className={'subtitle'}>Dotazník sa ukladá automaticky a je možné ho dokončiť na viackrát. Väčšina otázok sa dá preskočiť, pokiaľ neviete odpoveď alebo nemáte čas. </div>
            </div>
            <div className="container mb-5 pb-4 d-print">
              <h1>DOTAZNÍK K NOVÉMU PROJEKTU</h1>
              <div className={'subtitle'}>
                V prípade otázok nám zavolajte na <a className='link-secondary' href='tel:+421918040888'>0918 040 888</a> alebo napíšte na <a className='link-secondary' href='mailto:info@narative.sk'>info@narative.sk</a>
              </div>
            </div>

            {sections.map((section, i) => {
              const activeQuestions = this.getActiveQuestions(section.questions)
              return (<BriefSection
                questions={activeQuestions.map((question) => {
                  return {
                    ...question,
                    absoluteId: absoluteId++,
                    focusedQuestion: this.state.focusedQuestion,
                    focusedQuestionMax: this.state.focusedQuestionMax,
                  }
                })}
                key={i}
                sectionValue={section}
                setSectionValue={this.setSectionValue}
                setFocusedQuestion={this.setFocusedQuestion}
                title={section.title}
                number={i + 1}
                formValue={this.state.formValue}
                forceValueUpdate={this.state.forceValueUpdate}
              />)
            })}

            {showSubmitButton && <div className={'container submit-wrapper'}>
              <div className={'submit-error'}>
                {this.state.submitError}
              </div>
              <HoverBtn className="brief-submit-btn" handleClick={this.onSubmit}>
                <div>Odoslať dotazník</div>
              </HoverBtn>
              {
                this.state.isSubmitted && <img className="loader" src="/images/svg/loader.svg" alt={'loader'} />
              }
            </div>}
          </div>}

          <div>
            {(this.state.isSubmittedSuccessfully === true) ? <section key={3} id={'afterSubmit'} ref={this.afterSubmitRef} data-text={'2'}>
              <div className={'container d-flex'}>
                <div className='block'>
                  <h1>Váš dotazník bol úspešne odoslaný</h1>
                  <h2>Kópia v PDF vám príde na e-mail. Po preštudovaní zadania vás budeme čoskoro kontaktovať. Ďakujeme!</h2>

                  <HoverBtn id={'contact-btn'} type={'submit'} className={' mt-md-0 contact-btn'} size={1.25} handleClick={() => {
                    briefApi.sessionId = null
                    this.setState({
                      ...initData,
                      formReady: true,
                    },
                    )
                  }}
                  >
                      Chcem vyplniť ďalší projekt
                  </HoverBtn>
                
                </div>
              </div>
            </section> : (null)}
          </div>
          <div />
          <div />
          <div />
        </div>
      </BriefLayout>
    )
  }
}

export default Brief

