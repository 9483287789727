import WebsiteContent, { WebsiteContentValidation } from '../inputs/websiteContent'
import { getQuestionById, switchDefaultValidation } from '../briefHelpers'
import Switch from '../inputs/switch'
import WebsitePages, { WebsitePagesValidation } from '../inputs/websitePages'
import React from 'react'

const data = {
  id: 'CONTENT',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.website,
  title: 'Obsah',
  indicatorTitle: 'Obsah',
  questions: [
    {
      id: 'WEBSITE_CONTENT',
      component: WebsiteContent,
      data: {
        title: 'Máte pripravené texty na Váš web?',
        subtitle: 'Texty, dokumenty, produkty a ich popis, atď.',
        required: false,
        validationFunction: WebsiteContentValidation,
      },
      value: {
        textToggle: true,
        imagesToggle: true,
      },
    },
    {
      id: 'CARD_SORTING',
      component: Switch,
      data: {
        title: <>Máme Vám pomôcť so zatriedením obsahu webu do logických sekcií / cardsorting?</>,
        subtitle: 'Keď si nie ste istý, ako by mal byť Váš web ideálne členený, pre Vás je určená táto možnosť.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno',
        labelLeft: 'Nie',
      },
      value: true,
    },
    {
      id: 'WEBSITE_PAGES',
      component: WebsitePages,
      data: {
        title: 'Chcete naznačiť, ktoré podstránky bude web obsahovať?',
        subtitle: 'Nemusí to byť finálne, ale pomôže nám to odhadnúť rozsah webu a jeho zložitosť.',
        required: false,
        showStar: false,
        useEnterKey: true,
       /*  isRequiredDefValue: false,
        toggleIsRequired: true,
        showStar: false, */
        validationFunction: WebsitePagesValidation,
      },
      value: {
        /* toggle: true, */
        checkboxes: {
          selected: {
        /*     'ABOUT_US': null,
            'NEWS': null,
            'CONTACT': null,
            'BLOG': null,
            'TESTIMONIALS': null,
            'CATALOG': null,
            'FAQ': null,
            'PORTFOLIO': null, */
          },
        },
      },
    },
  ],
}

export default data