import React from 'react'
import PropTypes from 'prop-types'
import OldLogo from '../inputs/oldLogo'
import Radio from '../inputs/radio'
import HoverBtn from '../../HoverBtn'

const QuestionSubmit = props => {
  const { isValid, isRequired, onSubmit, onSkip, useEnterKey, customErrorMessage, value, componentType } = props
  if (isValid && typeof isValid === 'boolean') {
    return (
      <div className={'question-submit'}>
        <HoverBtn handleClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}>
          <div className="inner-cont">
            <div>
              <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24">
                <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
              </svg>
            </div>
            OK
          </div>
        </HoverBtn>
    
        {useEnterKey &&
          <>
            <span className='d-none d-md-block'>
              alebo použite <span className={'key'}>ENTER</span>
            </span>
          </>
        }
      </div>
    )
  } else {
    if (!isRequired) {
      return (
        <div className={'question-submit'} >
           <HoverBtn className={isValid && typeof isValid === 'boolean' ? '' : 'gray-to-red'} handleClick={(e) => {
              e.preventDefault()
              onSkip()
            }}>
            <div className="inner-cont">
             
              {isValid && typeof isValid === 'boolean'
                ?
                <>
                  <div>
                    <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24">
                      <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
                    </svg>
                  </div>
                  OK
                </>
                :
                <>
                  <i className="la la-arrow-right mr-1" /> PRESKOČIŤ
                </>
              }
              
            </div>
            </HoverBtn>
            {useEnterKey &&
              <>
                <span className='d-none d-md-block'>
                  alebo použite <span className={'key'}>ENTER</span>
                </span>
              </>
            }
        </div>
      )
    } else {
      let errorMsg = [Radio, OldLogo].includes(componentType) ?
        'Pre pokračovanie v dotazníku je nutné označiť jednu z možností' :
        'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'

      if (customErrorMessage != null) {
        if (typeof customErrorMessage === 'function') {
          errorMsg = customErrorMessage(value, componentType)
        } else {
          errorMsg = customErrorMessage
        }
      }

      return (
        <div className={'question-submit'}>
          <div className={'error'}>{errorMsg}</div>
        </div>
      )
    }
  }

  // return (null)
}

QuestionSubmit.propTypes = {
  value: PropTypes.any,
  componentType: PropTypes.any,
  isValid: PropTypes.bool.isRequired,
  useEnterKey: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  customErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

QuestionSubmit.defaultProps = {
  useEnterKey: true,
}
export default QuestionSubmit
