import React, { Component } from 'react'
import { childValue } from '../briefHelpers'
import Checkbox from './checkbox'
import TextInput from './textInput'

export const LogoTextValidation = (value) => {
  const { name, inventName } = value
  return !(!(name && name.replace(/(\r\n|\n|\r)/gm, '').length > 0) && !(inventName && inventName.selected && inventName.selected['INVENT_NAME'] != null))
}

class LogoText extends Component {
  render() {
    const { value, setValue, formValue } = this.props

    const [nameValue, nameSetValue] = childValue('name', value, setValue)
    const [inventNameValue, inventNameSetValue] = childValue('inventName', value, setValue)

    return (
      <div className='avoid'>
        <div
          className={
            inventNameValue &&
            inventNameValue.selected &&
            inventNameValue.selected.id === 'INVENT_NAME' ? 'hidden' : 'visible'}
        >
          <TextInput
            setValue={nameSetValue}
            value={nameValue}
            formValue={formValue}
            options={{}}
          />
        </div>
        <Checkbox setValue={inventNameSetValue} value={inventNameValue} options={{
          options: [
            {
              id: 'INVENT_NAME',
              label: 'Chceme, aby ste nám vymysleli názov',
              type: 'default',
            },
          ],
        }}/>
      </div>
    )
  }
}

export default LogoText