import React, { Component } from 'react'
import * as  PropTypes from 'prop-types'

class Switch extends Component {
  render() {
    const { setValue, value, options } = this.props
    const { labelLeft, labelRight } = options

    return (
      <div
        className={`switch avoid ${value ? 'active' : ''}`}>
        <div dangerouslySetInnerHTML={{ __html: labelLeft }}></div>
        <div
          style={{
            marginLeft: labelLeft == null ? 0 : null,
            marginRight: labelRight == null ? 0 : null,
          }}
          className={`box`}
          onClick={(e) => {
            e.preventDefault()
            setValue(!value)
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: labelRight }}></div>
      </div>
    )
  }
}

Switch.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.shape({
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
  }).isRequired,
}


export default Switch