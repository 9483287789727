import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import RadioOption from './radioOption'
import RadioOptionInput from './radioOptionInput'
import RadioOptionFile from './radioOptionFile'
import { childValue } from '../briefHelpers'
import SwitchInput from './switchInput'
import RadioOptionAreaInput from './radioOptionAreaInput'

class Radio extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(id, data, switchValue = true, required = false) {
    const { setValue, value, options } = this.props
    const selectedOptionType = options.options.find((opt) => opt.id === id).type
    if (!switchValue) {
      return setValue({
        selected: {
          type: selectedOptionType,
          id,
          data,
          required
        },
      })
    }

    if (value && value.selected != null && value.selected.id === id && !options.required) {
      // Ak znova klikne na uz vybranu moznost a otazka nieje povinna tak zrusi vybranie
      setValue({
        selected: null,
      })
    } else {
      setValue({
        selected: {
          type: selectedOptionType,
          id,
          data,
          required
        },
      })
    }
  }

  render() {
    const { value, setValue, options } = this.props

    return (
      <div className={'radio avoid'}>
        {options.options.map((option, i) => {
          switch (option.type) {
            case 'input':
              return <RadioOptionInput
                key={i}
                label={option.label}
                required={option.required}
                onChange={(data, sv) => this.onChange(option.id, data, sv, option.required)}
                selected={value && value.selected && (value.selected.id === option.id)}
                data={value && value.selected && value.selected.data}
                placeholder={option.placeholder}
              />
            case 'text':
              return <RadioOptionAreaInput
                key={i}
                options={options}
                label={option.label}
                onChange={(data, sv) => this.onChange(option.id, data, sv, option.required)}
                selected={value && value.selected && (value.selected.id === option.id)}
                data={value && value.selected && value.selected.data}
                placeholder={option.placeholder}
              />
            case 'file':
              return <RadioOptionFile
                key={i}
                label={option.label}
                onChange={(data, sv) => this.onChange(option.id, data, sv)}
                selected={value && value.selected && (value.selected.id === option.id)}
                data={value && value.selected && value.selected.data}
              />
            case 'switchText':
              return <>
                <div className='radio-option'>
                  <div className='label mt-3 mb-2'>{option.label}</div>
                </div>
                <SwitchInput setValue={setValue} value={value} options={option.options} />

              </>
            case 'custom':
              const [customChildValue, customChildSetValue] = childValue('customChild', value, setValue)
              const Component = option.component
              const selected = value && value.selected && (value.selected.id === option.id)
              return <div key={i}>
                <RadioOption
                  label={option.label}
                  onChange={(data, sv) => this.onChange(option.id, data, sv)}
                  selected={selected}
                  data={value && value.selected && value.selected.data}
                  placeholder={option.placeholder}
                />
                {selected && <Component setValue={customChildSetValue} value={customChildValue} options={option}/>}
              </div>
            default:
              return <RadioOption
                key={i}
                label={option.label}
                onChange={(data, sv) => this.onChange(option.id, data, sv)}
                selected={value && value.selected && (value.selected.id === option.id)}
              />
          }
        })}
      </div>
    )
  }
}

Radio.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
      ]).isRequired,
      type: PropTypes.oneOf(['default', 'input', 'file', 'custom', 'switchText', 'text']),
      placeholder: PropTypes.string,
    })).isRequired,
  }).isRequired,
}


export default Radio