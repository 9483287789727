import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { mapRange } from '../../../utilities'
import { childValue } from '../briefHelpers'
import Checkbox from './checkbox'
import BriefTooltip from '../BriefTooltip'

export const SliderValidation = (value) => {
  if (value == null) return false
  const { slider } = value
  return calculateReal(slider) > 0
}

const calculateReal = (sliderPosition) => {
  return sliderPosition * 500
//return Math.round(num / 500) * 500;
}

class SliderPicker extends Component {
  constructor(props) {
    super(props)
    this.getNumber = this.getNumber.bind(this)
  }

  getNumber() {
    const { value } = this.props
    if (value == null || value.slider == null || value.slider === 0) {
      return 'Potiahnite smerom doprava:'
    }

    const sliderValue = value.slider
    if (sliderValue === 200) {
      return '100K+'
    }

    const roundedNumber = calculateReal(sliderValue);

    if (roundedNumber === 0) {
      return 'Potiahnite smerom doprava:'
    }

    return `${roundedNumber}`
  }

  render() {
    const { setValue, value } = this.props
    const [checkboxValue, checkboxSetValue] = childValue('checkbox', value, setValue)

    const [sliderValue, sliderSetValue] = childValue('slider', value, setValue)
    const num = this.getNumber()

    return (
      <div className={'price-slider avoid'}>
        <div className={'slider'}>
          <div className={'slider-label'}>
            <span className={'label'}>0</span>
            <span className={'price'}>{num}</span>
            <span className={'label'}>100K+</span>
          </div>
          <Slider
            min={0}
            max={200}
            defaultValue={sliderValue != null ? sliderValue : 0}
            vlaue={sliderValue}
            onChange={(val) => {
              sliderSetValue(val)
            }}/>
        </div>
      </div>
    )
  }
}

SliderPicker.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any.isRequired,
}


export default SliderPicker
