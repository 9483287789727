const HOST_API = 'https://api.narative.sk/api/pdf/dotaznik'


class BriefApi {
  constructor() {
    this.sessionId = null
    this._email = null
  }

  set email(email) {
    this._email = email
  }

  async sendBlob({ file, formData }) {

    try {
      const response = await fetch(`${HOST_API}`, {
        method: 'POST',
        body: JSON.stringify({ file, formData }),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      return response.json()
    } catch (e) {
      throw Error(e);
    }
  }

  async withSession(apiMethod) {
    if (this.sessionId != null) {
      let response = await apiMethod()
      if (response.status === 401) {
        this.sessionId = await this.getSession()
        response = await apiMethod()
        if (response.status === 401) {
          console.error('Unable to get new sessionId')
        }
      }
      return response
    } else {
      this.sessionId = await this.getSession()
      let response = await apiMethod()
      if (response.status === 401) {
        console.error('Unable to get new sessionId')
      }

      return response
    }
  }

  async getSession() {
    if (this._email == null) {
      return console.error('Email not specified!')
    }

    const email = this._email


    const response = await fetch(`${HOST_API}/session`, {
      method: 'POST',
      body: JSON.stringify({ clientEmail: email }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data.sessionId
  }

  async getFileUploadUrl() {
    if (this.sessionId) {
      return `${HOST_API}/${this.sessionId}/file`
    } else {
      this.sessionId = await this.getSession()
      return `${HOST_API}/${this.sessionId}/file`
    }
  }

  async save(formData) {

    const response = await this.withSession(async () => {
      return await fetch(`${HOST_API}/session/${this.sessionId}/save`, {
        method: 'POST',
        body: JSON.stringify({ formData: formData }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

    })
  }

  async submit() {
    try {
      const response = await this.withSession(async () => {
        return await fetch(`${HOST_API}/${this.sessionId}/submit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      })

      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return null
      }
    } catch (e) {
      console.error('Error while submitting form. ', e)
      return null
    }
  }

  async pdf() {
  }
}

export default new BriefApi()