import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Option from './radioOption'
import OptionInput from './radioOptionInput'
import OptionFile from './radioOptionFile'
import { childValue } from '../briefHelpers'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(id, data, switchValue = true) {
    const { setValue, value } = this.props

    const hasOldValue = (value != null && value.selected != null && value.selected[id] != null)
    let newValue = hasOldValue ? !value.selected[id] : true

    if (!switchValue) {
      newValue = hasOldValue ? value.selected[id] : true
    }

    const oldValue = value != null && value.selected != null ? value.selected : {}

    setValue({
      selected: {
        ...oldValue,
        [id]: newValue ? {
          id,
          data,
        } : null,
      },
    })
  }

  render() {
    const { value, setValue, options } = this.props
    console.log('value', value)
    return (
      <div className={`checkbox avoid ${options.twoCol ? 'two-col' : ''}`}>
        {options.options.map((option, i) => {

          const sel = value != null && value.selected != null ? value.selected[option.id] : null
          const selected = sel != null
       

          const data = selected ? sel.data : null

          const onChange = (data, switchValue = true) => this.onChange(option.id, data, switchValue)
          switch (option.type) {
            case 'input':
              return <OptionInput
                key={i}
                label={option.label}
                onChange={onChange}
                selected={selected}
                data={data}
                placeholder={option.placeholder}
              />
            case 'file':
              return <OptionFile
                key={i}
                label={option.label}
                onChange={onChange}
                selected={selected}
                data={data}
              />
            case 'custom':
              const [customChildValue, customChildSetValue] = childValue('customChild', value, setValue)
              const Component = option.component
  
              return <Option
                key={i}
                label={option.label}
                onChange={(data, sv) => this.onChange(option.id, data, sv)}
                selected={selected}
                data={value && value.selected && value.selected.data}
                placeholder={option.placeholder}
              >
                {selected && <Component setValue={customChildSetValue} value={customChildValue} options={option}/>}
              </Option>
            default:
              return <Option
                key={i}
                label={option.label}
                onChange={onChange}
                selected={selected}
              />
          }
        })}
      </div>
    )
  }
}

Checkbox.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.any,
      type: PropTypes.oneOf(['default', 'input', 'file', 'custom']),
      placeholder: PropTypes.string,
    })).isRequired,
  }).isRequired,
}


export default Checkbox