import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class AreaInput extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.textAreaRef = React.createRef();
  }
  resize(elem) {
    elem.style.height = 'auto';
    elem.style.height = elem.scrollHeight + 25 + 'px';
  }

  onChange(el, enter = false,) {
    let { required } = this.props.options
    const { setValue } = this.props
    
    let localValue = el.value

    if (enter) {
      localValue += '\n'
    }
    const trimedValue = localValue.replace(/\s/g, "")

    if (trimedValue.length === 0) {
      localValue = ''
    }
    
    setValue(localValue)
    this.resize(el)
    
    if (!required && localValue.length === 0) {
      return this.props.options.useEnterKey = true
    } 
    return this.props.options.useEnterKey = false
  }

  componentDidMount() {
    const { options, autofocus } = this.props
 
    const ref = options && options.ref != null ? options.ref.current : this.textAreaRef.current
    if (autofocus) {
      ref.focus()
    }
    this.resize(ref)
  }

  render() {
    const { setValue, value, options } = this.props
    const val = typeof value === 'string' ? value : ''
    return (
      <div className={'text-area avoid'}>
        <div className={'value print-display'}>
          {val}
        </div>
        <textarea
          ref={options && options.ref != null ? options.ref : this.textAreaRef}
          onKeyDown={(e) => {
            const pressHandler = options && options.onKeyPress != null ? options.onKeyPress : null
            if (pressHandler) pressHandler()
            const value = e.target.value.replace(/\s/g, "")
            if (e.key === 'Enter' && value.length > 0) {
                e.stopPropagation()
                e.preventDefault()
                this.onChange(e.target, true)
            }
          }}
          onScroll={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onWheel={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onChange={(e) => {
            this.onChange(e.target)
          }}
          value={val}
          data-value={val}
          placeholder={options && options.placeholder}
        />
      </div>
    )
  }
}

AreaInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any,
  autofocus: PropTypes.bool
}

export default AreaInput