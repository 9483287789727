import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { times, xor } from 'lodash'

class BriefSidebarIndicator extends Component {
  constructor(props) {
    super(props)
    this.clickCount = 0

    if (typeof window != 'undefined') {
      if (sessionStorage && sessionStorage.getItem('debug') === 'debug') {
        document.body.classList.add('debug')
      }
    }
  }

  render() {
  
    const { steps, currentStep, hide, sections, allQuestionsCount, focusedQuestion } = this.props
    const localCurrentStep = currentStep - 1
    let localSteps = steps
    if (localCurrentStep === 0) {
      localSteps = 1
    }

    const lineHeight = 16 + ((localCurrentStep) * 40)
    
    console.log(focusedQuestion)
    console.log(allQuestionsCount)
    return (
      <div className={'brief-sidebar-indicator ' + (hide ? 'hide-indicator' : '')}>
      {/*   <div className={'count'} onClick={() => {
          if (this.clickCount > 3) {
            document.body.classList.add('debug')
            sessionStorage.setItem('debug', 'debug')
          } else {
            this.clickCount++
          }
        }}>
          {localCurrentStep}/{localSteps}
        </div> */}
        <div className='mobile-progress' style={{width: focusedQuestion * 100 / allQuestionsCount + '%'}} />
        <div className="dot-wrapper">
          <div className={'line'} style={{ height: lineHeight }}/>
          {times(localSteps, (i) => (
            <div className={`dot ${i <= localCurrentStep ? 'active' : ''}`} key={i}><span className='indicator-title'>{ sections[i].indicatorTitle }</span></div>
          ))}
        </div>
      </div>
    )
  }
}

BriefSidebarIndicator.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default BriefSidebarIndicator
