import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RadioOptionInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  render() {
    const { label, onChange, selected, data, placeholder, required } = this.props

    let localLabel = label
    if(required){
      if (selected) {
        localLabel = localLabel.replaceAll('<span class="required">*</span>', '') + '<span class="required">*</span>'
      } else {
        localLabel = localLabel.replaceAll('<span class="required">*</span>', '')
      }
    }
    return (
      <div
        className={`radio-option flex-row avoid ${selected ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onChange()
          setTimeout(() => {
            if (this.inputRef && this.inputRef.current) {
              this.inputRef.current.focus()
            }
          }, 0)
        }}>
        <div className={`box`}>
          <i className="la la-check"/>
        </div>
        <div className={'label'}>
          <div dangerouslySetInnerHTML={{ __html: localLabel }}></div>
          {selected && <>
            <div className={'print-display'}>
              {data}
            </div>
            <input
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              ref={this.inputRef}
              className={'radio-option-input'}
              type={'text'}
              onChange={event => onChange(event.target.value, false)}
              value={data}
              placeholder={placeholder}
            />
          </>}
        </div>
      </div>
    )
  }
}

RadioOptionInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  
  selected: PropTypes.bool,
  data: PropTypes.any,
  placeholder: PropTypes.string,
}

RadioOptionInput.defaultProps = {
  required: true,
}

export default RadioOptionInput