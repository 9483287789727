import React, { Component } from 'react'
/* import Switch from './switch' */
import { childValue } from '../briefHelpers'
import Checkbox from './checkbox'

export const WebsitePagesValidation = (value, data) => {
  const { checkboxes } = value
/*   const { toggleIsRequired, isRequiredDefValue } = data
  if (isRequiredDefValue !== 'undefiened') {
    if (toggleIsRequired && toggle) {
      data.required = true
    } else {
      data.required = isRequiredDefValue
    }
  } */
  data.required = false
  if (!checkboxes) return false
  
  const isSelected = Object.values(checkboxes.selected).find(item => item !== null)
  if (!isSelected) return false
  
  if (checkboxes.selected.OTHER) {
    const other = checkboxes.selected.OTHER;
    data.required = true
    if(!other.data || other.data.replace(/\s/g, "").length === 0) return false
  }
  return true
}

class WebsitePages extends Component {
  render() {
    const { value, setValue, formValue } = this.props

  /*   const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue) */
    const [checkboxesValue, checkboxesSetValue] = childValue('checkboxes', value, setValue)


    return (
      <div>
        {/* <Switch
          formValue={formValue}
          value={toggleValue}
          setValue={toggleSetValue}
          options={{
            labelRight: 'Áno',
            labelLeft: 'Nie',
          }}
        /> */}
        <div className={`mt-4 avoid`}>
          <Checkbox setValue={checkboxesSetValue} value={checkboxesValue} options={{
            twoCol: true,
            options: [
              {
                id: 'ABOUT_US',
                label: 'O nás',
                type: 'default',
              },
              {
                id: 'GALERI',
                label: 'Galéria',
                type: 'default',
              },
              {
                id: 'NEWS',
                label: 'Novinky',
                type: 'default',
              },
              {
                id: 'PRICE_LIST',
                label: 'Blog',
                type: 'default',
              },
              {
                id: 'PORTFOLIO',
                label: 'Naše služby',
                type: 'default',
              },
              {
                id: 'BLOG',
                label: 'Kariéra',
                type: 'default',
              },
              {
                id: 'TESTIMONIALS',
                label: 'Katalóg produktov/služieb',
                type: 'default',
              },
              {
                id: 'CAREER',
                label: 'Dokumenty na stiahnutie',
                type: 'default',
              },
              {
                id: 'CATALOG',
                label: 'E-shop',
                type: 'default',
              },
              {
                id: 'SHOPPING_CART',
                label: 'Diskusné fórum',
                type: 'default',
              },
              {
                id: 'LOGIN',
                label: 'Cenník',
                type: 'default',
              },
              {
                id: 'DOWNLOAD',
                label: 'Prihlásenie/Registrácia',
                type: 'default',
              },
              {
                id: 'FAQ',
                label: 'Povedali o nás/Testimonial',
                type: 'default',
              },
              {
                id: 'FORUM',
                label: 'Mapa stránky',
                type: 'default',
              },
              {
                id: 'BAZAAR',
                label: 'FAQ/Často kladené otázky',
                type: 'default',
              },
              {
                id: 'CONTACT',
                label: 'Kontakt',
                type: 'default',
              },
              {
                id: 'CALCULATOR',
                label: 'Značky a výrobcovia',
                type: 'default',
              },
              {
                id: 'OTHER',
                label: 'Iné:',
                type: 'input',
                placeholder: 'Vpíšte sem text',
                data: ''
              },
            ],
          }}/>
        </div>
      </div>
    )
  }
}

export default WebsitePages