import React, { Component } from 'react'
import Question from './question'
import PropTypes from 'prop-types'
import SectionTitle from './components/sectionTitle'

class BriefSection extends Component {
  constructor(props) {
    super(props)
    this.setQuestionValue = this.setQuestionValue.bind(this)
  }

  setQuestionValue(questionId, value, absoluteId) {
    const { setSectionValue, sectionValue, setFocusedQuestion } = this.props
    if (value != null) {
      const newSectionValue = {
        ...sectionValue,
        questions: sectionValue.questions.map((question) => {
          if (question.id === questionId) {
            return {
              ...question,
              value: value,
            }
          }
          return question
        }),
      }

      setSectionValue(newSectionValue)
    }

    setFocusedQuestion(absoluteId + 1)
  }

  render() {
    const { title, number, questions, setFocusedQuestion, forceValueUpdate } = this.props

    const isDisabled = questions[0] != null && questions[0].absoluteId > questions[0].focusedQuestionMax
    return (
      <section className={isDisabled ? 'disabled' : ''}>
        {
          questions.map((question, i) => (
            <Question
              onClick={(e) => {
                e.preventDefault()
                setFocusedQuestion(question.absoluteId)
              }}
              setQuestionValue={(id, v) => this.setQuestionValue(id, v, question.absoluteId)}
              showSectionTitle={i === 0}
              key={i}
              sectionTitle={title}
              sectionNumber={number}
              number={question.absoluteId + 1}
              focused={question.absoluteId === question.focusedQuestion}
              disabled={question.absoluteId > question.focusedQuestionMax}
              forceValueUpdate={forceValueUpdate}
              {...question}
            />
          ))
        }
      </section>
    )
  }
}

BriefSection.propTypes = {
  sectionValue: PropTypes.any.isRequired,
  questions: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  setSectionValue: PropTypes.func.isRequired,
  setFocusedQuestion: PropTypes.func.isRequired,
  forceValueUpdate: PropTypes.bool.isRequired,
}


export default BriefSection