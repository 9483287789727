import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import * as yup from 'yup'

class ListTwoColumns extends Component {
  constructor(props) {
    super(props)
    this.firstColInputRef = React.createRef()
    this.secondColInputRef = React.createRef()
  }

  removeItem(index) {
    const { setValue, value } = this.props
    setValue({
      items: value.items.filter((item, i) => {
        return index !== i
      }),
    })
  }

  addItem() {
    const { setValue, value } = this.props
    if (value != null && value.newInputCol1 != null && value.newInputCol1.length > 0){
      const isValied = yup.string().matches(
              /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-za-z0-9_-]+(\.[a-za-z]+)+(\/)?.([\w\?[a-za-z-_%\/@?]+)*([^\/\w\?[a-za-z0-9_-]+=\w+(&[a-za-z0-9_]+=\w+)*)?$/,
              'enter correct url!'
      ).required('please enter website').isValidSync(value.newInputCol1)
    
      if(!isValied) return
      setValue({
        items: value.items != null ? [...value.items, [value.newInputCol1, value.newInputCol2]] : [[value.newInputCol1, value.newInputCol2]],
        newInputCol1: null,
        newInputCol2: null,
      })

    if (this.firstColInputRef && this.firstColInputRef.current) {
      this.firstColInputRef.current.focus()
    }
    }
  }

  onDataChange(index, newValue, colIndex) {
    const { setValue, value } = this.props
    setValue({
      items: value.items.map((item, i) => {
        if (index === i) {
          return colIndex === 1 ? [
            item[0],
            newValue,
          ] : [
            newValue,
            item[1],
          ]
        }
        return item
      }),
    })
  }

  render() {
    const { setValue, value, options } = this.props

    const items = value != null && value.items ? value.items : []
    return (
      <ul className={'list two-columns avoid'}>
        {items.map((item, i) => {

          let component = <div key={i}>
            <input
              className={'no-fake-cursor first print-hide'}
              onChange={(e) => this.onDataChange(i, e.target.value, 0)}
              value={item[0]}
              type={'text'}
            />
            <div className={'print-display'}>
              {item[0]}
            </div>
            <input
              className={'no-fake-cursor print-hide'}
              onChange={(e) => this.onDataChange(i, e.target.value, 1)}
              value={item[1]}
              type={'text'}
            />
            <div className={'print-display'}>
              {item[1]}
            </div>
          </div>

          return (
            <li key={i}>
              {component}
              <i
                onClick={() => {
                  this.removeItem(i)
                }}
                className={'la la-remove'}
              />
            </li>
          )
        })}

        <li>
          <input
            ref={this.firstColInputRef}
            className={'no-fake-cursor first print-hide'}
            placeholder={options.placeholderCol1}
            onChange={(e) => {
              setValue({ newInputCol1: e.target.value })
            }}
            onKeyPress={(e) => {
             /*  if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                if (this.secondColInputRef && this.secondColInputRef.current) {
                  this.secondColInputRef.current.focus()
                }
              } */
            }}
            value={value != null && value.newInputCol1 != null ? value.newInputCol1 : ''}
            type={'text'}
          />
          <div className={'print-display'}>{value != null && value.newInputCol1 != null ? value.newInputCol1 : ''}</div>
          <input
            ref={this.secondColInputRef}
            className={'no-fake-cursor print-hide'}
            placeholder={options.placeholderCol2}
            onChange={(e) => {
              setValue({ newInputCol2: e.target.value })
            }}
            onKeyPress={(e) => {
             /*  if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                this.addItem()
              } */
            }}
            value={value != null && value.newInputCol2 != null ? value.newInputCol2 : ''}
            type={'text'}
          />
          <div className={'print-display'}>{value != null && value.newInputCol2 != null ? value.newInputCol2 : ''}</div>
          <i
            onClick={() => {
              this.addItem()
            }}
            className={'la la-plus'}
          />
        </li>
      </ul>
    )
  }
}

ListTwoColumns.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  options: PropTypes.any.isRequired,
}

export default ListTwoColumns