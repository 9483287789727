import React, { Component } from 'react'
import * as  PropTypes from 'prop-types'
import Switch from './switch'
import TextInput from './textInput'
import { childValue } from '../briefHelpers'

export const SwitchInputValidation = (value) => {
  const { textInput, switchInput } = value
  return switchInput === false || (switchInput === true && textInput && textInput.length > 0)
}

class SwitchInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  render() {
    const { setValue, value, options } = this.props

    const [switchValue, switchSetValue] = childValue('switchInput', value, setValue)
    const [textInputValue, textInputSetValue] = childValue('textInput', value, setValue)
    if (switchValue) {
      options.labelRight = options.labelRight.replaceAll('<span class="required">*</span>', '') + '<span class="required">*</span>'
    } else {
      options.labelRight = options.labelRight.replaceAll('<span class="required">*</span>', '')
    }
    return (
      <div className={'switchInput avoid'}>
        <Switch setValue={(value) => {
          if (value) {
            setTimeout(() => {
              this.inputRef && this.inputRef.current && this.inputRef.current.focus()
            }, 0)
          }
          switchSetValue(value)
        }} value={switchValue} options={options}/>
        {switchValue && <TextInput setValue={textInputSetValue} value={textInputValue} options={{
          ...options,
          ref: this.inputRef,
        }}/>}
      </div>
    )
  }
}

SwitchInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  options: PropTypes.shape({
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
  }).isRequired,
}


export default SwitchInput