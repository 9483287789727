import React, { Component } from 'react'
import Tooltip from "react-tooltip-lite"
import question from '../../../static/images/question.svg'
import * as PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

class BriefTooltip extends Component {

  stopAll = (e) => {
    e.stopPropagation();
    
  }
  render() {
    return (
      <span className={'brief-tooltip'} onClick={(e) => this.stopAll(e)}>
        <Tooltip tagName={'span'} {...this.props}>
          <div className='d-flex align-items-center'>
            {this.props.children}
            <Svg className="ml-1" width="18px" height="18px" src={question} uniquifyIDs={false}/>
             {/*  <i className={'la la-question-circle'} /> */}
            </div>
        </Tooltip>
      </span>
    )
  }
}

BriefTooltip.propTypes = {
    content: PropTypes.string.isRequired,
};


export default BriefTooltip