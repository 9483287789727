import React, { Component } from 'react'
import { childValue } from '../briefHelpers'
import File from './fileUploader'
import Radio from './radio'

export const OldLogoValidation = (value) => {
  const { toggle } = value
  const selectedId = toggle != null && toggle.selected != null && toggle.selected.id ? toggle.selected.id : null
  if (selectedId === 'NEW_LOGO') {
    return true
  }
/*
  if (selectedId === 'IMPROVE_OLD_LOGO' && file != null && file.files && file.files.length > 0) {
    return true
  }*/

  if (selectedId === 'IMPROVE_OLD_LOGO') {
    return true
  }
  return false
}

class OldLogo extends Component {
  render() {
    const { value, setValue } = this.props
    const [toggleValue, toggleSetValue] = childValue('toggle', value, setValue)
    const [fileValue, fileSetValue] = childValue('file', value, setValue)

    return (
      <div>
        <Radio setValue={toggleSetValue} value={toggleValue} options={{
          required: true,
          options: [
           {
              id: 'NEW_LOGO',
              label: 'Vytvoriť nové logo',
              type: 'default',
            },
            {
              id: 'IMPROVE_OLD_LOGO',
              label: 'Vylepšiť staré logo',
              type: 'default',
            }
          ],
        }} />
        {value && value.toggle && value.toggle.selected.id === 'IMPROVE_OLD_LOGO' &&
          <File setValue={fileSetValue} value={fileValue} subtitle={'Nahrajte sem svoje staré logo'} />
        }
      </div>
    )
  }
}

export default OldLogo