import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class List extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef();
  }

  removeItem(index) {
    const { setValue, value } = this.props
    setValue({
      items: value.items.filter((item, i) => {
        return index !== i
      }),
    })
    this.inputRef.current.focus()
  }

  addItem() {
    const { setValue, value } = this.props
    if (value != null && value.newInput != null && value.newInput.length > 0)
      setValue({
        items: value.items != null ? [...value.items, value.newInput] : [value.newInput],
        newInput: null,
      })
    this.inputRef.current.focus()
  }

  onDataChange(index, newValue) {
    const { setValue, value } = this.props
    setValue({
      items: value.items.map((item, i) => {
        if (index === i) {
          return newValue
        }
        return item
      }),
    })

  }

  componentDidMount() {
    const { autofocus } = this.props
    if (autofocus) {
      const ref = this.inputRef
      if (ref) {
        ref.current.focus()
      }
    }
  }

  render() {
    const { setValue, value, options } = this.props

    const items = value != null && value.items ? value.items : []
    return (
      <ul className={'list avoid'}>
        {items.map((item, i) => {

          let component = <><input
            className={'no-fake-cursor print-hide'}
            onChange={(e) => this.onDataChange(i, e.target.value)}
            value={item}
            type={'text'}
          />
            <div className={'print-display'}>
              {item}
            </div>
          </>

          return (
            <li key={i}>
              {component}
              <i
                onClick={() => {
                  this.removeItem(i)
                }}
                className={'la la-remove'}
              />
            </li>
          )
        })}

        <li>
          <input
            ref={this.inputRef}
            className={'no-fake-cursor print-hide'}
            placeholder={options.placeholder}
            onChange={(e) => {
              setValue({ newInput: e.target.value })
            }}
            /*   onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                  this.addItem()
                }
              }} */
            value={value != null && value.newInput != null ? value.newInput : ''}
            type={'text'}
          />
          <div className={'print-display'}>{value != null && value.newInput != null ? value.newInput : ''}</div>
          <i
            onClick={() => {
              this.addItem()
            }}
            className={'la la-plus'}
          />
        </li>
      </ul>
    )
  }
}

List.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any.isRequired,
  autofocus: PropTypes.bool
}

export default List