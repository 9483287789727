import { flatten } from 'lodash'
import * as yup from 'yup'

export const getQuestionById = (formValue, questionId) => {
  const questions = formValue.sections.map(section => {
    return section.questions
  })
  return flatten(questions).find((question) => {
    return question.id === questionId
  })
}

export const noEnterValidation = (value) => {
  if (!value) return false
  return value.replace(/\s/g, "").length > 0
}

export const setQuestionValue = (formValue, questionId, questionValue) => {

}

export const checkboxDefaultValidation = (value, data) => {
  if (value == null) return false
  if (typeof data.isRequiredDefValue === 'boolean') {
    data.required = data.isRequiredDefValue
  }
  
  data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné označiť jednu z možností'

  if (!value) return false
  const { selected } = value
  if (!selected) return false
  const values = Object.values(selected)

  const empteInput = values.filter(opt => {
    if (opt == null) {
      return false
    }
    if (opt.id.search('OTHER') >= 0 || opt.id.search('web') >= 0) {
      data.required = true
      data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'
      return !opt.data
    }

    return false
  })

  if (empteInput.length !== 0) {
    return false
  }

  const notEmpty = values.filter(opt => {
    if (opt == null) {
      return false
    }

    if (opt.id.search('OTHER') >= 0) {
      return !!opt.data
    }

    if (opt.data != null) {
      return opt.data.length > 0
    }
    return true
  })

  if (data.hasChildren) {
    const hasMainValue = values.filter(item => {
      if(item){
        console.log(item.id, item.id.search('MAIN'))
        }
      return item && item.id.search('MAIN') >= 0
    }).length > 0
    
    if (!hasMainValue) {
      return false
    }
  }

  return notEmpty.length > 0
}

export const listDefaultValidation = (value, data) => {
  if (value == null) return false

  let localValue = value.newInput
  const items = value.items
  if (typeof value === 'string') {
    localValue = value
  }
  if (items && items.length > 0) return true
  if (localValue.replace(/\s/g, "")) return true

  return false

}

export const radioDefaultValidation = (value, data) => {
  
  if (value == null) return false
  if(value.selected &&value.selected.type !== 'text'){
    data.useEnterKey = true
  }

  data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné označiť jednu z možností'
  if (value.selected && (value.selected.id === 'OTHER' || value.selected.id === 'OTHER_1' || value.selected.id === 'WEB' || value.selected.id === 'WEB_1') || (value.switchInput && !value.textInput)) {
    data.useEnterKey = false
    data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'
   
    if (value.customChild) {
      
      return value.customChild.replace(/\s/g, "").length > 0
    }
    if (value.selected.data && value.selected.data.replace(/\s/g, "").length > 0) {
      if(value.selected && value.selected.type !== 'text'){
        data.useEnterKey = true
      }
    }
    if (!value.selected.data || value.selected.data === 'undefined') {
      data.required = value.selected.required
      data.required = true
      return false
    }

    if (value.selected.id === 'WEB' || value.selected.id === 'WEB_1') {
      return yup.string().matches(
              /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-za-z0-9_-]+(\.[a-za-z]+)+(\/)?.([\w\?[a-za-z-_%\/@?]+)*([^\/\w\?[a-za-z0-9_-]+=\w+(&[a-za-z0-9_]+=\w+)*)?$/,
              'enter correct url!'
      ).required('please enter website').isValidSync(value.selected.data)
    }
  }
 
  if (value.switchInput && !value.textInput) {
    return false
  }

  
  /*  if (value.selected != null && value.selected.type === 'file') {
      return value.selected.data &&
        value.selected.data.files &&
        value.selected.data.files.length > 0
    }*/


  return value && value.selected != null && value.selected.id != null
}

export const radioDefaultValidationRequiredInput = (value, data) => {
  if (value.selected != null && value.selected.type === 'input') {
    const isValid = value.selected.data && value.selected.data.length > 0
    if (value.selected.required) {
      data.required = true
    } else {
      data.required = false
    }

    return isValid
  }
  return value && value.selected != null && value.selected.id != null
}

export const childValue = (key, value, setValue) => {
  const childValue = value != null && value.hasOwnProperty(key) ? value[key] : null
  const childSetState = (val) => {
    const newValue = typeof val === 'object' ? { ...childValue, ...val } : val
    setValue( //TODO: Toto si niesom isty ci nieco nepokazi kedze to je len prva hodnota
      {
        [key]: newValue,
      })
  }

  return [childValue, childSetState]
}

export const switchDefaultValidation = (value, data) => {
  if (!value) return true

  const { toggleIsRequired, isRequiredDefValue, toggleEnter } = data

  if (isRequiredDefValue !== 'undefiened') {
    const { switchValue, conditionalRender, switchInput, textInput } = value

    if (toggleIsRequired && switchValue) {
      data.required = true
      
      if (toggleEnter !== 'undefiened') {
        data.useEnterKey = toggleEnter
      }
       
      if (!conditionalRender) return false

    } else if (toggleIsRequired && switchInput) {
      data.required = true
      if (!textInput) return false

    } else {
      data.useEnterKey = true
      data.required = isRequiredDefValue
    }
  }


  return true
}