import React, { Component } from 'react'
import PropTypes from 'prop-types'
import File from './fileUploader'

class RadioOptionFile extends Component {
  render() {
    const { label, onChange, selected, data } = this.props
    return (
      <div
        className={`radio-option file avoid ${selected ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          onChange()
          if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
          }
        }}>
        <div className={`box`}>
          <i className="la la-check"/>
        </div>
        <div className={'text'}>
          {label}
          {selected && <File setValue={(value) => {onChange(value, false)}} value={data}/>}
        </div>
      </div>
    )
  }
}

RadioOptionFile.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  data: PropTypes.any,
}


export default RadioOptionFile
