import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class TextInput extends Component {
  render() {
    const { setValue, value, options, cancelClickEvent, onFocus } = this.props
    const val = value === null || typeof value !== 'string' ? '' : value

    return (
      <div className={'text-input avoid'}>
        <div className={'print-display'}>
          { val }
        </div>
        <input
          ref={options.ref != null ? options.ref : null}
          onKeyPress={options.onKeyPress != null ? options.onKeyPress : null}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onFocus={onFocus}
          value={val}
          type={options.type}
          placeholder={options.placeholder}
          onClick={(e) => {
            if (cancelClickEvent) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        />
      </div>
    )
  }
}

TextInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  formValue: PropTypes.any,
  options: PropTypes.any.isRequired,
  cancelClickEvent: PropTypes.bool,
  onFocus: PropTypes.func,
}


export default TextInput