import { checkboxDefaultValidation, childValue, getQuestionById, radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import Switch from '../inputs/switch'
import Radio from '../inputs/radio'
import Checkbox from '../inputs/checkbox'
import React from 'react'
import TextInput from '../inputs/textInput'
import BriefTooltip from '../BriefTooltip'

export const LanguageMutationsValidation = (value, data) => {
  if (value == null) return false;

  const { radio } = value
  data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné označiť jednu z možností'
  
  if (radio && radio.selected && radio.selected.id === 'MULTI_LANG') {
    data.customErrorMessage = 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku'
    return radio.selected.data && radio.selected.data.length > 0;
  }

  return radio && radio.selected != null
}
const LanguageMutations = ({ value, setValue }) => {
  const [radioValue, radioSetValue] = childValue('radio', value, setValue)
  const [checkboxValue, checkboxSetValue] = childValue('checkbox', value, setValue)

  return (<>
      <Radio value={radioValue} setValue={radioSetValue} options={{
        required: true,
        options: [
          {
            id: 'SINGLE_LANG',
            label: 'Web bude iba v jednom jazyku – slovenčina',
            type: 'default',
          },
          {
            id: 'MULTI_LANG',
            label: 'Web bude mať viacero jazykov:',
            required: true,
            type: 'input',
            placeholder: 'Vypíšte jazyky...',
            
          },
        ],
      }
      }/>
      <Checkbox setValue={checkboxSetValue} value={checkboxValue} options={{
        options: [
          {
            id: 'YES',
            label: 'Máme záujem o zabezpečenie profesionálneho prekladu',
            type: 'default',
          },
        ],
      }}/>
    </>
  )
}


const data = {
  id: 'PROGRAMMING',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.website,
  title: 'Programovanie',
  indicatorTitle: 'Programovanie',
  questions: [
    {
      id: 'PROGRAMMING_STACK',
      component: Radio,
      data: {
        title: <>Preferujete konkrétny programovací jazyk / <BriefTooltip content={'Kombinácia programovacích jazykov a metód'}>dev-stack</BriefTooltip>?</>,
        subtitle: 'Keď máte jasnú predstavu, vieme Vám samozrejme vyhovieť.',
        required: false,
        useEnterKey: true,
        showStar: false,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'NO',
            label: 'Nechávame to na Vás',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Programovacie jazyky:',
            type: 'input',
            placeholder: 'Napr. PHP, JS, Java a pod.',
          },
          {
            id: 'OTHER_1',
            label: 'Opensource CMS:',
            type: 'input',
            placeholder: 'Napr. Wordpress, Drupal a pod.',
          },
        ],
      },
      value: {
        selected: {
          id: 'NO',
        },
      },
    },
    {
      id: 'CMS',
      component: Switch,
      data: {
        title: 'Budete si priať administračný systém (CMS) na spravovanie obsahu?',
        subtitle: 'Aby ste si mohli sami upravovať texty, fotky, produkty, ceny, a všetko čo je možné editovať.',
        required: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno (odporúčame)',
      },
      value: true,
    },
    {
      id: 'LANGUAGE_MUTATIONS',
      component: LanguageMutations,
      data: {
        title: 'Jazykové mutácie',
        subtitle: 'Pretože koľko viete jazykov, toľkokrát ste človekom.',
        required: true,
        validationFunction: LanguageMutationsValidation,
      },
    },
    {
      id: 'ADDITIONAL_FUNCTIONALITY',
      component: Checkbox,
      data: {
        title: 'Základné funkcie',
        subtitle: 'Niektoré základné funkcie na inšpiráciu. (Funkcie pre e-shopy nájdete v samostatnej sekcii)',
        required: false,
        twoCol: true,
        showStar: false,
        options: [
          {
            id: 'SEARCH',
            label: 'Vyhľadávanie',
            type: 'default',
          },
          {
            id: 'SOC_NET_INTEGRATION',
            label: 'Zamknutý obsah',
            type: 'default',
          },
          {
            id: 'FILTER',
            label: 'Spravovanie bannerov',
            type: 'default',
          },
          {
            id: 'NEWSLETTER',
            label: 'Platobná brána',
            type: 'default',
          },
          {
            id: 'COMMENTS',
            label: 'Filter výsledkov',
            type: 'default',
          },
          {
            id: 'USER_ROLES',
            label: 'Vernostný program',
            type: 'default',
          },
          {
            id: 'PRODUCT_COMPARISION',
            label: 'Pridávanie komentárov',
            type: 'default',
          },
          {
            id: 'PRODUCT_RATING',
            label: 'Konfigurátor produktu',
            type: 'default',
          },
          {
            id: 'RESERVATION_FORM',
            label: 'Zdieľanie na sociálne siete',
            type: 'default',
          },
          {
            id: 'INVOICES',
            label: 'GDPR systém',
            type: 'default',
          },
          {
            id: 'WAREHOUSE_SYSTEM',
            label: 'Newsletter',
            type: 'default',
          },
          {
            id: 'DISCOUNT_SYSTEM',
            label: 'Objednávací kalendár',
            type: 'default',
          },
          {
            id: 'AFFILIATE',
            label: 'Sťahovanie e-booku',
            type: 'default',
          },
          {
            id: 'WATCHDOG',
            label: 'Zľavový kupón/darček',
            type: 'default',
          },
          {
            id: 'CHAT',
            label: 'Užívateľské role',
            type: 'default',
          },
          {
            id: 'FACEBOOK_LOGIN',
            label: 'Chat lišta',
            type: 'default',
          },
          {
            id: 'BANNER_ADS',
            label: 'Prihlasovanie pomocou Facebooku',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: checkboxDefaultValidation,
      },
      /* value: {
        selected: {
          'SEARCH': true,
          'SOC_NET_INTEGRATION': true,
          'NEWSLETTER': true,
        },
      }, */
    },
  ],
}

export default data