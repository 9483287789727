import React, { Component } from 'react'
import Radio from './radio'
import BriefTooltip from '../BriefTooltip'

export const ProjectTypeCustomErrorFunction = (value) => {
  if (value == null) return

  const { website, app, websiteType, appWebsite } = value

  const isWebsiteTypeSelected = websiteType &&
    websiteType.selected &&
    websiteType.selected.id != null

  const isAppTypeSelected = appWebsite &&
    appWebsite.selected &&
    appWebsite.selected.id != null

  if ((website && !isWebsiteTypeSelected) && (app && !isAppTypeSelected)) {
    return 'Pre pokračovanie v dotazníku vyberte typ webstránky a mobilnej aplikácie, o ktoré máte záujem'
  }

  if (website && !isWebsiteTypeSelected) {
    return 'Pre pokračovanie v dotazníku vyberte typ webstránky, o ktorý máte záujem'
  }

  if (app && !isAppTypeSelected) {
    return 'Pre pokračovanie v dotazníku vyberte typ mobilnej aplikácie, o ktorý máte záujem'
  }

  return 'Pre pokračovanie v dotazníku vyberte typy projektov, o ktoré máte záujem'
}
export const ProjectTypeValidationFunction = (value) => {
  const { website, app, design, websiteType, appWebsite } = value

  if (website) {
    const isWebsiteTypeSelected = websiteType &&
      websiteType.selected &&
      websiteType.selected.id != null

    if (!isWebsiteTypeSelected) {
      return false
    }
    /* if (websiteType.selected.id === 'OTHER') {
      if (!websiteType.selected.data) {
        return false
      }
    } */
  }

  if (app) {
    const isAppTypeSelected = appWebsite &&
      appWebsite.selected &&
      appWebsite.selected.id != null

    if (!isAppTypeSelected) {
      return false
    }
  }

  if (!website && !app && !design) {
    return false
  }
  return true
}

class ProjectType extends Component {
  constructor(props) {
    super(props)
    this.setAppWebsite = this.setAppWebsite.bind(this)
    this.setProjectValue = this.setProjectValue.bind(this)
  }


  toggleButton(key) {
    const { setValue, value } = this.props

    if (key === 'app' &&
      value[key] === true &&
      value.appWebsite &&
      value.appWebsite.selected &&
      value.appWebsite.selected.id === 'APP_AND_WEB') {
      const { websiteType } = value
      if (websiteType.selected != null) {
        websiteType.selected.data = ''
      }
    }
    setValue({ [key]: !value[key] })
  }

  setAppWebsite(newValue) {
    const { setValue, value } = this.props

   /*  if (value.appWebsite &&
      value.appWebsite.selected &&
      (value.appWebsite.selected.id === 'APP_AND_WEB' || value.appWebsite.selected.id === 'APP_AND_LANDING_PAGE')) {
      const { websiteType } = value
      if (websiteType.selected != null) {
        websiteType.selected.data = ''
        websiteType.selected.id = null
      }
    } */

    if (newValue.selected == null) {
      return
    }

    let websiteType = value.websiteType
    let dif = {}
   /*  switch (newValue.selected.id) {
      case 'ONLY_APP':
        // dif.website = false
        break
      case 'APP_AND_WEB':
        dif.website = true
        websiteType = {
          selected: {
            id: 'OTHER',
            data: 'Podľa mobilnej aplikácie',
          },
        }
        break
      case 'APP_AND_LANDING_PAGE':
        dif.website = true
        websiteType = {
          selected: {
            id: 'ONE_PAGE',
          },
        }
        break
      case 'HAVE_WEB':
        break
      default:
        return
    } */
    setValue({
      appWebsite: newValue,
      websiteType: websiteType,
      ...dif,
    })
  }

  setProjectValue(value) {
    const { setValue } = this.props

    setValue(value, false)
   
  }

  render() {
    const { setValue, value } = this.props

    return (
      <div id='project-type' className={'project-type'}>
        <div className={'row avoid'}>
          <div className="col-12 col-lg-4">
            <button
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.toggleButton('website')
                }
              }}
              onClick={() => {
                this.toggleButton('website')
              }}
              className={value['website'] ? 'active' : ''}
            ><i className="la la-desktop"/> Webstránka
            </button>
          </div>
          <div className="col-12 col-lg-4">
            <button
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.toggleButton('app')
                }
              }}
              onClick={() => {
                this.toggleButton('app')
              }}
              className={value['app'] ? 'active' : ''}
            ><i className="la la-mobile-phone"/> Mobilná aplikácia
            </button>
          </div>
          <div className="col-12 col-lg-4">
            <button
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.toggleButton('design')
                }
              }}
              onClick={() => {
                this.toggleButton('design')
              }}
              className={value['design'] ? 'active' : ''}
            ><i className="la la-certificate"/> Logo / Branding
            </button>
          </div>
        </div>
        <div className={'row mt-4 avoid'}>
          <div className={'col-12 col-md-12 text-center'}>
            <h4>(Môžete označiť viacero možností naraz. Podľa toho sa neskôr objavia príslušné časti dotazníka)</h4>
          </div>
        {/*   <div className={'col-12 col-md-4 mt-md-0 mt-2'}>
            <LinkBtn size={1}>
              <a href={'/nase-prace/grafika'} target={'_blank'} rel="noreferrer">Zobraziť referencie logo & branding</a>
            </LinkBtn>
          </div> */}
        </div>
        <div className="row ">
          <div className={`col-12 col-lg-4 mt-5 mt-md-5 fade avoid ${value.website ? 'show' : 'hide'}`}>
            <h3>Kategória projektu</h3>
            <Radio
              setValue={(value) => {
                this.setProjectValue({ websiteType: value })
              }}
              value={value.websiteType}
              options={{
                options: [
                  {
                    id: 'WEB',
                    label: 'Firemný web',
                    type: 'default',
                  },
                  {
                    id: 'ESHOP',
                    label: 'E-shop',
                    type: 'default',
                  },
              /*     {
                    id: 'BLOG',
                    label: 'Blog',
                    type: 'default',
                  }, */
                  {
                    id: 'PORTAL',
                    label: 'Portál',
                    type: 'default',
                  },
                  {
                    id: 'CRM',
                    label: 'ERP/CRM',
                    type: 'default',
                  },
                  {
                    id: 'ONE_PAGE',
                    label: 'Microsite',
                    type: 'default',
                  },
                  {
                    id: 'OTHER',
                    label: 'Iné:',
                    required: false,
                    type: 'input',
                    placeholder: 'Vpíšte sem text',
                  },
                ],
              }}/>
          </div>
          <div className={`col-12 col-lg-4 md-2 mt-5 avoid fade avoid ${value.app ? 'show' : 'hide'}`}>
            <h3>Bude appka prepojená s webom?</h3>
            <h4> Často existuje aj appka aj web súčasne.</h4>
            <Radio
              setValue={this.setAppWebsite}
              value={value.appWebsite}
              options={{
                options: [
                  {
                    id: 'ONLY_APP',
                    label: 'Aplikácia bude dostupná iba na mobiloch',
                    type: 'default',
                  },
                  {
                    id: 'APP_AND_WEB',
                    label: 'Chceme vytvoriť aj webovú verziu / portál',
                    type: 'default',
                  },
                  {
                    id: 'APP_AND_LANDING_PAGE',
                    label: <>Chceme iba <BriefTooltip content={'Slúži na modernú prezentáciu appky, vrátane linkov na stiahnutie v obchodoch'}>prezentačnú microsite</BriefTooltip></>,
                    type: 'default',
                  },
                  {
                    id: 'HAVE_WEB',
                    label: 'Máme už existujúcu webovú verziu',
                    type: 'default',
                    /* placeholder: 'Adresa sučasného webu', */
                  },
                ],
              }}/>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectType

