import { getQuestionById, radioDefaultValidation, switchDefaultValidation } from '../briefHelpers'
import OldLogo, { OldLogoValidation } from '../inputs/oldLogo'
import Radio from '../inputs/radio'
import LogoText, { LogoTextValidation } from '../inputs/logoText'
import SwitchInput from '../inputs/switchInput'
import LogoDescription, { LogoDescriptionValidation } from '../inputs/logoDescription'
import Color, { ColorValidation } from '../inputs/color'
import Switch from '../inputs/switch'
import Branding, { BrandingValidation } from '../inputs/branding'

const data = {
  id: 'LOGO_AND_BRANDING',
  active: (formValue) => getQuestionById(formValue, 'PROJECT_TYPE').value.design,
  title: 'Logo & Branding',
  indicatorTitle: 'Identita',
  questions: [
    {
      id: 'OLD_LOGO',
      component: OldLogo,
      data: {
        title: 'Prajete si vylepšiť Vaše staré logo alebo vytvoriť úplne nové?',
        required: true,
        validationFunction: OldLogoValidation,
      },
      value: {
        selected: {
          id: null,
          data: {},
        },
      },
    },
    {
      id: 'DESIGN_VARIANTS_COUNT',
      component: Radio,
      data: {
        title: 'Počet návrhov nového loga',
        subtitle: 'Plnohodnotné koncepty / návrhy, z ktorých si vyberiete 1, ktorý ďalej rozpracujeme na základe Vašich pripomienok.',
        required: false,
        showStar: false,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vyplniť túto otázku',
        options: [
          {
            id: 'ONE',
            label: '1',
            type: 'default',
          },
          {
            id: 'THREE',
            label: '3',
            type: 'default',
          },
          {
            id: 'FIVE',
            label: '5',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Iné:',
            type: 'input',
            placeholder: 'Vpíšte sem text',
          },
        ],
        validationFunction: radioDefaultValidation,
      },
      value: {
        selected: {
          id: 'THREE',
          data: {},
        },
      },
    },
    {
      id: 'LOGO_TEXT',
      component: LogoText,
      data: {
        title: 'Názov v logu',
        subtitle: 'Text loga. Predstavuje Vašu značku.',
        required: true,
        validationFunction: LogoTextValidation,
      },
      value: {
        selected: {
          id: null,
          data: {},
        },
      },
    },
    {
      id: 'MOTTO',
      component: SwitchInput,
      data: {
        title: 'Bude súčasťou loga doplňujúci text / slogan?',
        subtitle: 'Niekedy je logo doplnené aj ďalším textom, napr. Nike – Just do it.',
        required: false,
        isRequiredDefValue: false,
        toggleIsRequired: true,
        showStar: false,
        validationFunction: switchDefaultValidation,
        labelRight: 'Áno:',
        labelLeft: 'Nie',
        placeholder: 'Vpíšte sem text',
      },
    },
    {
      id: 'GLIPH',
      component: Radio,
      data: {
        title: 'Má logo obsahovať aj grafický znak (symbol, glyph?)',
        subtitle: 'Popri texte v logu je hlavným motívom symbol, ktorý ho dopĺňa.',
        required: false,
        showStar: false,
        validationFunction: radioDefaultValidation,
        options: [
          {
            id: 'YES',
            label: 'Áno, nápad na symbol nechávame na Vás',
            type: 'default',
          },
          {
            id: 'OTHER',
            label: 'Áno, máme nápad na znak:',
            placeholder: ' Vpíšte sem text',
            type: 'input',
          },
          {
            id: 'NO',
            label: 'Nie',
            type: 'default',
          },
        ],
      },
      value: {
        selected: {
          id: 'YES',
        },
      },
    },
    {
      id: 'LOGO_DESCRIPTION',
      component: LogoDescription,
      data: {
        title: 'Sú nejaké konkrétne logá, ktoré sa Vám páčia?',
        showStar: false,
        subtitle: 'Môžeme tak pochopiť bližšie Váš vkus. Napíšte názvy spoločností alebo ich web stránky.',
        required: false,
        validationFunction: LogoDescriptionValidation,
        useEnterKey: true,
      },
      value: {
        toggle: false
      }
    },
    {
      id: 'LOGO_COLOR',
      component: Color,
      data: {
        title: 'Akú preferujete farebnosť loga?',
        subtitle: 'Buď farby necháte na nás, alebo môžeme zvážiť aj Vaše nápady.',
        required: true,
        customErrorMessage: 'Pre pokračovanie v dotazníku je nutné vybrať farby kliknutím na štvorčeky',
        validationFunction: ColorValidation,
      },
     /*  value: {
        toggle: {
          selected: {
            id: 'NO',
          },
        },
      }, */
    },
    {
      id: 'LOGO_MANUAL',
      component: Switch,
      data: {
        title: 'Budete si želať spracovať aj logomanuál?',
        subtitle: 'Dokument, ktorý ukazuje rôzne použitia loga tak, aby vyzeralo stále super na všetkých médiách.',
        labelRight: 'Áno',
        labelLeft: 'Nie',
        required: false,
        validationFunction: switchDefaultValidation,
      },
      value: true,
    },
    {
      id: 'BRANDING',
      component: Branding,
      data: {
        title: 'Budete si želať k logu vypracovať aj balík branding?',
        subtitle: 'Aplikujeme Vám logo na rôzne médiá, aby všetko spolu krásne hralo.',
        required: false,
        showStar: false,
        validationFunction: BrandingValidation,
      },
      value: {
       /*  toggle: true, */
        checkboxes: {
          selected: {
           /*  'BUSINESS_CARD': true,
            'BANNER': true,
            'EMAIL_SIGNATURE': true,
            'HEADER': true,
            'FOLDER': true, */
          },
        },
      },
    },
  ],
}

export default data